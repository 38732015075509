import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {Wallet} from '@/model/resource/Wallet'

export class ListWalletSchema extends DefaultSchema {
  readonly name = 'ListWallet'

  readonly fieldSet: FieldSet<Wallet> = {
    order: (): FieldComponent => ({
      is: Component.Render,
    }),
    logoUrl: (schema): FieldComponent => ({
      is: Component.RenderImage,
      bind: {
        src: schema.model.logoUrl,
        alt: this.translateFrom(schema.fieldName),
        innerClass: 'w-32',
      },
    }),
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
    websiteUrl: (): FieldComponent => ({
      is: Component.Render,
    }),
    blockchainVersionTitles: (): FieldComponent => ({
      is: Component.Render,
    }),
    platformTypeTitles: (): FieldComponent => ({
      is: Component.Render,
    }),
    dappConnectionTitles: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
