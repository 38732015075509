import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet} from '@simpli/meta-schema'
import {Contract} from '@/model/resource/Contract'

export class ExportContractSchema extends DefaultSchema {
  readonly name = 'ExportContract'

  readonly fieldSet: FieldSet<Contract> = {
    idContractPk: schema => schema.model.idContractPk,
    blockchainVersion: schema => schema.model.blockchainVersion?.title ?? '-',
    title: schema => schema.model.title,
    deploymentDate: schema => $.filter.datetime(schema.model.deploymentDate),
    hash: schema => schema.model.hash,
    dapp: schema => schema.model.dapp?.title ?? '-',
  }
}
