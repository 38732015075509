import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {IOrganizationCollectionResourcesHolder} from '@/model/collection/OrganizationCollection'
import {Organization} from '@/model/resource/Organization'

export class FilterOrganizationSchema extends DefaultSchema
  implements IOrganizationCollectionResourcesHolder {
  readonly name = 'FilterOrganization'

  readonly fieldSet: FieldSet<Organization> = {}
}
