



























































import NavbarMenu from '@/components/NavbarMenu.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {Component} from 'vue-property-decorator'
import AppFooter from '@/components/AppFooter.vue'
import {MetaInfo} from 'vue-meta'
import GetGasOpenModalButton from '@/components/getGas/GetGasOpenModalButton.vue'
import LastPriceUpdated from '@/components/LastPriceUpdated.vue'
import PoweredBy, {PoweredByItem} from '@/components/PoweredBy.vue'
import FilterNetwork from '@/components/filters/FilterNetwork.vue'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import {SimpleListTokensTableItemSchema} from '@/schema/resource/TokensTableItem/SimpleListTokensTableItemSchema'
import SwapModal from '@/components/swapToken/SwapModal.vue'
import ScrollableTable from '@/components/ScrollableTable.vue'

@Component({
  components: {
    ScrollableTable,
    SwapModal,
    PoweredBy,
    LastPriceUpdated,
    AppFooter,
    NavbarMenu,
    GetGasOpenModalButton,
    FilterNetwork,
  },
})
export default class TokensPriceView extends MixinScreenSize {
  poweredByList = [
    {
      url: 'https://flamingo.finance/',
      alt: 'flamingo icon',
      filename: 'flamingo-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://www.forthewin.network/#/',
      alt: 'ftwicon',
      filename: 'for-the-win-logo.svg',
    } as PoweredByItem,
    {
      url: 'https://neofura-1.gitbook.io/api-docs/',
      alt: 'magnet icon',
      filename: 'magnet-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://carrot-fi.xyz/',
      alt: 'carrot swap icon',
      filename: 'carrot-swap.svg',
    } as PoweredByItem,
  ]

  schema = new SimpleListTokensTableItemSchema()

  collection = new TokensTableItemsCollection()

  swappableTokensSymbol: string[] = []

  currentOrderBy: string | null = null

  async created() {
    this.applyCollection()
    await this.populateResources()
  }

  applyCollection() {
    this.collection.withoutTotal = true
    this.collection.orderBy = 'marketCap'
    this.collection.minOrder = 0
    this.collection.asc = false
    this.collection.perPage = this.collection.total
    this.collection.active = true
  }

  async populateResources() {
    const promises: Array<Promise<any>> = [this.collection.expand()]

    await Promise.all(promises)

    this.swappableTokensSymbol = this.schema.swappableTokensSymbol
  }

  async handleOrderAscDesc(key: number) {
    const {params} = this.collection
    const value = this.schema.orderByList[key]

    if (value !== 'tokens') {
      params.ascending =
        value !== this.currentOrderBy ? false : !params.ascending
    } else {
      params.ascending =
        value !== this.currentOrderBy ? true : !params.ascending
    }

    params.orderBy = value

    this.collection.orderBy = value
    this.collection.asc = !params.ascending
    this.collection.clearFilters().addFilter(params)
    this.currentOrderBy = value
  }

  handleFilterNetwork(networkIdentifier: string) {
    this.collection.networkIdentifier = networkIdentifier
    this.collection.items = []
    this.collection.queryAsPage()
  }

  metaInfo(): MetaInfo {
    return {
      title: this.$translate('metadata.title.tokens'),
    }
  }
}
