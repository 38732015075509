import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {Explorer} from '@/model/resource/Explorer'

export class ListExplorerSchema extends DefaultSchema {
  readonly name = 'ListExplorer'

  readonly fieldSet: FieldSet<Explorer> = {
    order: (): FieldComponent => ({
      is: Component.Render,
    }),
    imageUrl: (schema): FieldComponent => ({
      is: Component.RenderImage,
      bind: {
        src: schema.model.imageUrl,
        alt: this.translateFrom(schema.fieldName),
        innerClass: 'w-32',
      },
    }),
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
    websiteUrl: (): FieldComponent => ({
      is: Component.Render,
    }),
    blockchainVersionTitles: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
