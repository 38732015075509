import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {PlatformType} from '@/model/resource/PlatformType'

export class ListPlatformTypeSchema extends DefaultSchema {
  readonly name = 'ListPlatformType'

  readonly fieldSet: FieldSet<PlatformType> = {
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
    isMobile: (): FieldComponent => ({
      is: Component.Render,
    }),
    isDesktop: (): FieldComponent => ({
      is: Component.Render,
    }),
    isWeb: (): FieldComponent => ({
      is: Component.Render,
    }),
    isHardware: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
