import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {PrivateCategory} from '@/model/resource/PrivateCategory'

export class ListPrivateCategorySchema extends DefaultSchema {
  readonly name = 'ListPrivateCategory'

  readonly fieldSet: FieldSet<PrivateCategory> = {
    idPrivateCategoryPk: (): FieldComponent => ({
      is: Component.Render,
    }),
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
    slug: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
