import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {INntTagCollectionResourcesHolder} from '@/model/collection/NntTagCollection'
import {NntTag} from '@/model/resource/NntTag'

export class FilterNntTagSchema extends DefaultSchema
  implements INntTagCollectionResourcesHolder {
  readonly name = 'FilterNntTag'

  readonly fieldSet: FieldSet<NntTag> = {}
}
