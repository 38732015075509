import {ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {ContractNep17} from '@/model/resource/ContractNep17'
import {TokenPriceSource} from '@/model/resource/TokenPriceSource'
import {Contract} from './Contract'

export class PriceHistory implements IResource {
  @ResponseSerialize(ContractNep17)
  contractNep17: ContractNep17 | null = null

  @ResponseSerialize(TokenPriceSource)
  tokenPriceSource: TokenPriceSource | null = null

  @ResponseSerialize(Contract)
  contract: Contract | null = null

  currentPrice: string | null = null
  lastDayPrice: string | null = null
  twoDaysAgoPrice: string | null = null
  threeDaysAgoPrice: string | null = null
  fourDaysAgoPrice: string | null = null
  fiveDaysAgoPrice: string | null = null
  lastWeekPrice: string | null = null
  lastUpdated: Date | null = null
  priceVariationLastDay: string | null = null
  priceVariationLastWeek: string | null = null

  get $id() {
    return this.contract?.idContractPk ?? 0
  }

  set $id(val) {
    if (this.contract !== null) {
      this.contract.idContractPk = val
    }
  }

  get $tag() {
    return String(this.$id)
  }
}
