import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {Global} from '@/model/resource/Global'

export class InputGlobalSchema extends DefaultSchema {
  readonly name = 'InputGlobal'
  readonly fieldSet: FieldSet<Global> = {
    dappImageDimension: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        maxlength: 11,
        step: 1,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    boostedDappMaxsize: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        maxlength: 11,
        step: 1,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    minDappTransactionCountForTrending: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        maxlength: 11,
        step: 1,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    newContractMailingList: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'textarea',
        label: this.translateFrom(schema.fieldName),
        class: 'w-94',
        rows: 5,
      },
    }),
  }

  get persistViewFields() {
    return [
      'dappImageDimension',
      'boostedDappMaxsize',
      'minDappTransactionCountForTrending',
    ]
  }
}
