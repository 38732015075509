import {$} from '@/facade'
import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseSerialize,
} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {GithubUser} from '@/model/resource/GithubUser'

@HttpExclude()
export class GithubUserCollection extends PageCollection<GithubUser> {
  resource?: IGithubUserCollectionResourcesHolder

  constructor() {
    super(GithubUser)
  }

  queryAsPage() {
    return this.listGithubUser()
  }

  async listGithubUser() {
    return await Request.get(`/client/github-user`, {params: this.params})
      .name('listGithubUser')
      .as(this)
      .getResponse()
  }

  async listExportGithubUser() {
    return await Request.get(`/client/github-user/export`, {
      params: this.params,
    })
      .name('listExportGithubUser')
      .as(this)
      .getResponse()
  }
}

export interface IGithubUserCollectionResourcesHolder {}
