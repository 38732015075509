import {HttpExclude, Request} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {DappConnection} from '@/model/resource/DappConnection'

@HttpExclude()
export class DappConnectionCollection extends PageCollection<DappConnection> {
  resource?: IDappConnectionCollectionResourcesHolder

  constructor() {
    super(DappConnection)
  }

  queryAsPage() {
    return this.listDappConnection()
  }

  async listDappConnection() {
    return await Request.get(`/client/dapp-connection`, {params: this.params})
      .name('listDappConnection')
      .as(this)
      .getResponse()
  }

  async listExportDappConnection() {
    return await Request.get(`/client/dapp-connection/export`, {
      params: this.params,
    })
      .name('listExportDappConnection')
      .as(this)
      .getResponse()
  }
}

export interface IDappConnectionCollectionResourcesHolder {}
