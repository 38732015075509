import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {IDappSocialCollectionResourcesHolder} from '@/model/collection/DappSocialCollection'
import {DappSocial} from '@/model/resource/DappSocial'
import {DappCollection} from '@/model/collection/DappCollection'
import {SocialTypeCollection} from '@/model/collection/SocialTypeCollection'

export class FilterDappSocialSchema extends DefaultSchema
  implements IDappSocialCollectionResourcesHolder {
  collectionDapp = new DappCollection().noPagination()
  collectionSocialType = new SocialTypeCollection().noPagination()

  readonly name = 'FilterDappSocial'

  readonly fieldSet: FieldSet<DappSocial> = {}
}
