import {$} from '@/facade'
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {Repository} from '@/model/resource/Repository'
import {RepositoryLanguageCollection} from '@/model/collection/RepositoryLanguageCollection'

export class RepositoryLanguage implements IResource {
  idRepositoryLanguagePk: number = 0

  @ResponseSerialize(Repository)
  repository: Repository | null = null

  language: string | null = null
  amountInBytes: number | null = null

  get $id() {
    return this.idRepositoryLanguagePk
  }

  set $id(val) {
    this.idRepositoryLanguagePk = val
  }

  get $tag() {
    return String(this.$id)
  }

  get idRepositoryFk() {
    if (!this.repository) return 0
    return this.repository.$id
  }

  set idRepositoryFk(val) {
    if (!this.repository) this.repository = new Repository()
    this.repository.$id = val
  }

  /**
   * Lists the instances of RepositoryLanguage
   */
  static async listRepositoryLanguage(params: any) {
    return await Request.get(`/client/repository-language`, {params})
      .name('listRepositoryLanguage')
      .as(RepositoryLanguageCollection)
      .getData()
  }

  /**
   * Lists the instances of RepositoryLanguage to export as a file
   */
  static async listExportRepositoryLanguage(params: any) {
    return await Request.get(`/client/repository-language/export`, {params})
      .name('listExportRepositoryLanguage')
      .as(RepositoryLanguageCollection)
      .getData()
  }

  /**
   * Gets a instance of a given ID of RepositoryLanguage
   */
  async getRepositoryLanguage(id: number) {
    return await Request.get(`/client/repository-language/${id}`)
      .name('getRepositoryLanguage')
      .as(this)
      .getData()
  }

  /**
   * Persists a new instance of RepositoryLanguage. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistRepositoryLanguage() {
    return await Request.post(`/client/repository-language`, this)
      .name('persistRepositoryLanguage')
      .asNumber()
      .getData()
  }
}
