






import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {TokensTableItem} from '@/model/resource/TokensTableItem'

@Component({})
export default class SwapInput extends Vue {
  @Prop({type: String, required: true}) value!: string
  @Prop({type: TokensTableItem, required: true}) token!: TokensTableItem
  decimals = this.token.marketInformation?.decimals ?? 8
  maxAmount = this.token.marketInformation?.totalSupply ?? String(100_000_000)
  regex = new RegExp(`^(0|([1-9]\\d*))(\\.\\d{0,${this.decimals}})?$`)

  get amountFormatted() {
    return Number(this.value).toLocaleString('en-US', {
      maximumFractionDigits: 10,
      minimumFractionDigits: 0,
    })
  }

  @Watch('token')
  onTokenChange() {
    this.decimals = this.token.marketInformation?.decimals ?? 8
    this.maxAmount =
      this.token.marketInformation?.totalSupply ?? String(100_000_000)
    this.regex = new RegExp(`^(0|([1-9]\\d*))(\\.\\d{0,${this.decimals}})?$`)
  }

  handleInput(event: Event) {
    const element = event.target as HTMLInputElement

    if (element.value.length && !this.regex.test(element.value)) {
      const index = element.value.search('[^0-9\\.]')
      element.value =
        index === -1
          ? element.value.slice(0, -1)
          : element.value.slice(0, index) + element.value.slice(index + 1)
      return
    }

    if (Number(element.value) > Number(this.maxAmount)) {
      element.value = element.value.slice(0, -1)
      return
    }

    this.$emit('input', element.value)
  }
}
