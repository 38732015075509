import {HttpExclude, Request} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {PlatformType} from '@/model/resource/PlatformType'

@HttpExclude()
export class PlatformTypeCollection extends ExpansibleCollection<PlatformType> {
  resource?: IPlatformTypeCollectionResourcesHolder

  constructor() {
    super(PlatformType)
  }

  queryAsPage() {
    return this.listPlatformType()
  }

  async listPlatformType() {
    return await Request.get(`/client/platform-type`, {params: this.params})
      .name('listPlatformType')
      .as(this)
      .getResponse()
  }

  async listExportPlatformType() {
    return await Request.get(`/client/platform-type/export`, {
      params: this.params,
    })
      .name('listExportPlatformType')
      .as(this)
      .getResponse()
  }
}

export interface IPlatformTypeCollectionResourcesHolder {}
