import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {ICategoryCollectionResourcesHolder} from '@/model/collection/CategoryCollection'
import {Category} from '@/model/resource/Category'

export class FilterCategorySchema extends DefaultSchema
  implements ICategoryCollectionResourcesHolder {
  readonly name = 'FilterCategory'

  readonly fieldSet: FieldSet<Category> = {}
}
