import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {BoostedDapp} from '@/model/resource/BoostedDapp'
import {DappCollection} from '@/model/collection/DappCollection'
import {BoostedDappCollection} from '@/model/collection/BoostedDappCollection'
import {Dapp} from '@/model/resource/Dapp'
import {$} from '@/facade'

export class InputBoostedDappSchema extends DefaultSchema {
  collectionDapp = new DappCollection().noPagination()
  collectionBoosted = new BoostedDappCollection().noPagination()

  readonly name = 'InputBoostedDapp'

  readonly fieldSet: FieldSet<BoostedDapp> = {
    dapp: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        label: this.translateFrom(schema.fieldName),
        items: this.filteredDapps(schema.model),
        disabled: !!$.route.params.id,
      },
    }),
    order: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        maxlength: 255,
        step: 1,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
  }

  filteredDapps(model: BoostedDapp) {
    const hasBoosted = ({idDappPk}: Dapp) =>
      this.collectionBoosted.items.some(({idDappFk}) => idDappFk == idDappPk)

    this.collectionDapp.items.forEach((dapp: Dapp) => {
      if (
        dapp.blockchainVersion?.tagName &&
        !dapp.title?.includes(dapp.blockchainVersion?.tagName)
      ) {
        dapp.title += ` (${dapp.blockchainVersion?.tagName})`
      }
    })

    return this.collectionDapp.items.filter(
      dapp => !hasBoosted(dapp) || model.idDappFk === dapp.idDappPk
    )
  }
}
