import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {User} from '@/model/resource/User'

export class ListUserSchema extends DefaultSchema {
  readonly name = 'ListUser'

  readonly fieldSet: FieldSet<User> = {
    email: (): FieldComponent => ({
      is: Component.Render,
    }),
    fullName: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
