import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {OrganizationCollection} from '@/model/collection/OrganizationCollection'
import {Repository} from '@/model/resource/Repository'
import {OrganizationCache} from '@/model/resource/OrganizationCache'
import {CAIterable, CAType} from '@/app/CAController'
import moment, {max, Moment} from 'moment'
import {DappSocial} from '@/model/resource/DappSocial'
import {OrganizationSocial} from '@/model/resource/OrganizationSocial'

export class Organization implements CAIterable {
  idOrganizationPk: number = 0

  @ResponseSerialize(Repository)
  repositories: Repository[] = []

  @ResponseSerialize(OrganizationCache)
  organizationCache: OrganizationCache | null = null

  @ResponseSerialize(OrganizationSocial)
  organizationSocial: OrganizationSocial[] | null = null

  title: string | null = null
  colorHex: string | null = null

  logoUrl: string | null = null
  repositoryUrl: string | null = null

  location: string | null = null

  establishedYear: number | null = null

  caType = CAType.ORGANIZATION

  get $id() {
    return this.idOrganizationPk
  }

  set $id(val) {
    this.idOrganizationPk = val
  }

  get $tag() {
    return String(this.title)
  }

  get activitiesCount() {
    return this.organizationCache?.linesOfCode ?? 0
  }

  get lastUpdateAt() {
    const lastCommitAt = moment(this.organizationCache?.lastCommitAt)
    const lastIssueAt = moment(this.organizationCache?.lastIssueAt)
    const lastPullAt = moment(this.organizationCache?.lastPullAt)

    const moments: Moment[] = []

    if (lastCommitAt.isValid()) {
      moments.push(lastCommitAt)
    }

    if (lastIssueAt.isValid()) {
      moments.push(lastIssueAt)
    }

    if (lastPullAt.isValid()) {
      moments.push(lastPullAt)
    }

    const result = max(moments).format()
    return moment(result).isValid() ? result : null
  }

  get idOrganizationCacheFk() {
    if (!this.organizationCache) return 0
    return this.organizationCache.$id
  }

  set idOrganizationCacheFk(val) {
    if (!this.organizationCache)
      this.organizationCache = new OrganizationCache()
    this.organizationCache.$id = val
  }

  /**
   * Lists the instances of Organization
   */
  static async listOrganization(params: any) {
    return await Request.get(`/client/organization`, {params})
      .name('listOrganization')
      .as(OrganizationCollection)
      .getData()
  }

  /**
   * Lists the instances of Organization to export as a file
   */
  static async listExportOrganization(params: any) {
    return await Request.get(`/client/organization/export`, {params})
      .name('listExportOrganization')
      .as(OrganizationCollection)
      .getData()
  }

  /**
   * Persists a new instance of Organization. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistOrganization() {
    return await Request.post(`/client/organization`, this)
      .name('persistOrganization')
      .asNumber()
      .getData()
  }

  /**
   * Remove an Organization.
   */
  async removeOrganization() {
    return await Request.delete(`/client/organization/${this.idOrganizationPk}`)
      .name('removeOrganization')
      .asNumber()
      .getData()
  }

  /**
   * Gets a instance of a given ID of Organization
   */
  async getOrganization(id: number) {
    return await Request.get(`/client/organization/${id}`)
      .name('getOrganization')
      .as(this)
      .getData()
  }
}
