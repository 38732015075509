




























































































































































































































import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import Component from 'vue-class-component'
import {Prop} from 'vue-property-decorator'
import {PropType} from 'vue'
import {TokensTableItem} from '@/model/resource/TokensTableItem'
import SwapRouteDetails from '@/components/swapToken/SwapRouteDetails.vue'
import {UNWRAPPING_FEE} from '@/libs/blockchain-services/constants/FlamingoSwapConstants'

@Component({
  components: {SwapRouteDetails},
})
export default class SwapTransactionDetailsCard extends MixinScreenSize {
  @Prop({type: TokensTableItem, required: true})
  tokenToReceive!: TokensTableItem
  @Prop({type: TokensTableItem, required: true})
  tokenToUse!: TokensTableItem
  @Prop({type: String, required: true})
  priceImpact!: string
  @Prop({type: String, required: true})
  liquidityProviderFee!: string
  @Prop({type: String, required: true})
  amountToUse!: string
  @Prop({type: String, required: true})
  amountToReceive!: string
  @Prop({type: String, required: true})
  minimumReceived!: string
  @Prop({type: String, required: true})
  sellingMaximum!: string
  @Prop({
    type: ((null as unknown) as Object) as PropType<Boolean | null>,
    required: true,
  })
  priceImpactIsHigh!: boolean
  @Prop({
    type: ((null as unknown) as Object) as PropType<Boolean | null>,
    required: true,
  })
  priceImpactIsExtremellyHigh!: boolean
  @Prop({type: String, required: true}) priceInverse!: string
  @Prop({type: String, required: true}) lastAmountChanged!: string
  @Prop({type: Array, required: true}) route!: TokensTableItem[]
  @Prop({type: Boolean, required: true}) wrappingNeo!: boolean
  @Prop({type: Boolean, required: true}) unwrappingNeo!: boolean

  get unwrappingFee() {
    return UNWRAPPING_FEE
  }
}
