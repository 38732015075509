import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {$} from '@/facade'
import {GasCalculatorTarget} from '@/model/resource/GasCalculatorTarget'
import moment from 'moment'
import {EnvHelper} from '@/helpers/EnvHelper'

@HttpExclude()
export class GasCalculatorTargetCollection extends ExpansibleCollection<
  GasCalculatorTarget
> {
  resource?: IGasCalculatorTargetCollectionResourcesHolder
  requestName: string = 'listTargets'
  @RequestExpose() withoutTotal: boolean | null = null
  @RequestExpose() isCandidate: boolean | null = null

  constructor() {
    super(GasCalculatorTarget)
  }

  get sortedCandidates(): GasCalculatorTarget[] {
    const sortedList = []

    if (this.noCandidate) {
      sortedList.push(this.noCandidate)
    }

    const sortedCandidates = this.items
      .filter(
        item =>
          item.isCandidate &&
          item.scriptHash !== EnvHelper.VUE_APP_NO_CANDIDATE_SCRIPT_HASH
      )
      .sort((a, b) => {
        return Number(b.numberOfVotes) - Number(a.numberOfVotes)
      })

    return sortedList.concat(sortedCandidates)
  }

  get memberAndCouncil(): GasCalculatorTarget[] {
    return this.sortedCandidates.slice(0, 22)
  }

  get noCandidate(): GasCalculatorTarget | undefined {
    return this.items.find(
      item => item.scriptHash === EnvHelper.VUE_APP_NO_CANDIDATE_SCRIPT_HASH
    )
  }

  get neoBurger(): GasCalculatorTarget | undefined {
    return this.items.find(
      item => item.scriptHash === EnvHelper.VUE_APP_BNEO_SCRIPT_HASH
    )
  }

  /**
   * Gets council - consensus, council and candidates by group name
   */
  get groupedMembers() {
    const candidateList = this.sortedCandidates.map((item, index) => {
      item.order = index

      return item
    })

    const noCandidateGroup = {
      groupName: undefined,
      groupMembers: [candidateList[0]],
    }

    const consensusGroup = {
      groupName: $.t('components.gasCalculator.gasCalculatorCard.consensus'),
      groupMembers: candidateList.slice(1, 8),
    }

    const councilGroup = {
      groupName: $.t('components.gasCalculator.gasCalculatorCard.council'),
      groupMembers: candidateList.slice(8, 22),
    }

    const candidatesGroup = {
      groupName: $.t('components.gasCalculator.gasCalculatorCard.candidates'),
      groupMembers: candidateList.slice(22),
    }

    return [noCandidateGroup, consensusGroup, councilGroup, candidatesGroup]
  }

  get lastDailyGasRateTimeUpdated() {
    return moment
      .utc(this.items[0].dailyGasRateUpdatedTime)
      .format($.t('dateFormat.datetimeFull') as string)
  }

  async queryAsPage() {
    return this.listTargets()
  }

  async listTargets() {
    return await Request.get(`/client/gas-calculator-target`, {
      params: this.params,
    })
      .name(this.requestName)
      .as(this)
      .getResponse()
  }
}

export interface IGasCalculatorTargetCollectionResourcesHolder {}
