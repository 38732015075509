import {EnvHelper} from '@/helpers/EnvHelper'
import {BSNeo3 as BsNEO3} from '@cityofzion/bs-neo3'

export const bsNeo3 = new BsNEO3('neo3', {
  id: 'mainnet',
  name: 'MainNet',
  url: EnvHelper.VUE_APP_RPC_URL,
})

export type BSNeo3 = typeof bsNeo3
