












import {Vue, Component, Prop} from 'vue-property-decorator'

@Component
export default class SwapTokenAccountBalance extends Vue {
  @Prop({type: Number, required: true}) tokenAccountBalance!: number

  get balanceFormatted() {
    return `${Number(this.tokenAccountBalance).toLocaleString('en-US', {
      maximumFractionDigits: 10,
      minimumFractionDigits: 0,
    })}`
  }
}
