import {$} from '@/facade'
import {Request} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {BlockchainHourlyCacheCollection} from '@/model/collection/BlockchainHourlyCacheCollection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'

export class BlockchainHourlyCache implements IResource {
  idBlockchainHourlyCachePk: number = 0

  blockchainVersion: BlockchainVersion | null = null
  contractCount: number | null = null
  addressCount: number | null = null
  transactionCount: number | null = null
  date: string | null = null

  get $id() {
    return this.idBlockchainHourlyCachePk
  }

  set $id(val) {
    this.idBlockchainHourlyCachePk = val
  }

  get $tag() {
    return String(this.$id)
  }

  /**
   * Lists the instances of BlockchainHourlyCache
   */
  static async listBlockchainHourlyCache(params: any) {
    return await Request.get(`/client/blockchain-hourly-cache`, {params})
      .name('listBlockchainHourlyCache')
      .as(BlockchainHourlyCacheCollection)
      .getData()
  }

  /**
   * Lists the instances of BlockchainHourlyCache to export as a file
   */
  static async listExportBlockchainHourlyCache(params: any) {
    return await Request.get(`/client/blockchain-hourly-cache/export`, {params})
      .name('listExportBlockchainHourlyCache')
      .as(BlockchainHourlyCacheCollection)
      .getData()
  }

  /**
   * Gets a instance of a given ID of BlockchainHourlyCache
   */
  async getBlockchainHourlyCache(id: number) {
    return await Request.get(`/client/blockchain-hourly-cache/${id}`)
      .name('getBlockchainHourlyCache')
      .as(this)
      .getData()
  }

  /**
   * Persists a new instance of BlockchainHourlyCache. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistBlockchainHourlyCache() {
    return await Request.post(`/client/blockchain-hourly-cache`, this)
      .name('persistBlockchainHourlyCache')
      .asNumber()
      .getData()
  }
}
