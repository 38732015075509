import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet} from '@simpli/meta-schema'
import {PrivateCategory} from '@/model/resource/PrivateCategory'

export class ExportPrivateCategorySchema extends DefaultSchema {
  readonly name = 'ExportPrivateCategory'

  readonly fieldSet: FieldSet<PrivateCategory> = {
    idPrivateCategoryPk: schema => schema.model.idPrivateCategoryPk,
    title: schema => schema.model.title,
    slug: schema => schema.model.slug,
  }
}
