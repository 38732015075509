import {HttpExclude, Request} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {SupportedServer} from '@/model/resource/SupportedServer'

@HttpExclude()
export class SupportedServerCollection extends ExpansibleCollection<
  SupportedServer
> {
  resource?: ISupportedServerCollectionResourcesHolder

  constructor() {
    super(SupportedServer)
  }

  queryAsPage() {
    return this.listSupportedServer()
  }

  async listSupportedServer() {
    return await Request.get(`/client/supported-server/list`, {
      params: this.params,
    })
      .name('listSupportedServer')
      .as(this)
      .getResponse()
  }

  async listSupportedServerAdmin() {
    return await Request.get(`/client/supported-server`, {
      params: this.params,
    })
      .name('listSupportedServer')
      .as(this)
      .getResponse()
  }
}

export interface ISupportedServerCollectionResourcesHolder {}
