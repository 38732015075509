import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {SocialType} from '@/model/resource/SocialType'
import FileManagerWithList from '@/components/FileManagerWithList.vue'
import {$} from '@/facade'

export class InputSocialTypeSchema extends DefaultSchema {
  readonly name = 'InputSocialType'

  readonly fieldSet: FieldSet<SocialType> = {
    title: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 70,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    iconUrl: (schema): FieldComponent => ({
      is: FileManagerWithList,
      bind: {
        folder: 'social_type',
        compressDimension: $.auth.global.socialTypeIconDimension,
        crop: false,
        class: 'scoped__image-url',
      },
    }),
  }
}
