import {$} from '@/facade'
import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseSerialize,
} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {RepositoryLanguage} from '@/model/resource/RepositoryLanguage'
import {RepositoryCollection} from '@/model/collection/RepositoryCollection'

@HttpExclude()
export class RepositoryLanguageCollection extends PageCollection<
  RepositoryLanguage
> {
  resource?: IRepositoryLanguageCollectionResourcesHolder
  @RequestExpose() idRepositoryFk: number[] = []
  @RequestExpose() minAmountInBytes: number | null = null
  @RequestExpose() maxAmountInBytes: number | null = null

  constructor() {
    super(RepositoryLanguage)
  }

  get repository() {
    return (
      this.resource?.collectionRepository.getManyIds(this.idRepositoryFk) ??
      null
    )
  }

  set repository(input) {
    this.idRepositoryFk = input?.map(item => item?.$id) ?? []
  }

  queryAsPage() {
    return this.listRepositoryLanguage()
  }

  async listRepositoryLanguage() {
    return await Request.get(`/client/repository-language`, {
      params: this.params,
    })
      .name('listRepositoryLanguage')
      .as(this)
      .getResponse()
  }

  async listExportRepositoryLanguage() {
    return await Request.get(`/client/repository-language/export`, {
      params: this.params,
    })
      .name('listExportRepositoryLanguage')
      .as(this)
      .getResponse()
  }
}

export interface IRepositoryLanguageCollectionResourcesHolder {
  collectionRepository: RepositoryCollection
}
