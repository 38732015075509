import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {NntTag} from '@/model/resource/NntTag'

export class ListNntTagSchema extends DefaultSchema {
  readonly name = 'ListNntTag'

  readonly fieldSet: FieldSet<NntTag> = {
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
