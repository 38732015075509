import {
  GenericInvocationBuilder,
  SwapNeoUsingNeoProxyArgs,
} from '@/model/wallets/invocationsBuilder/GenericInvocationBuilder'
import {EnvHelper} from '@/helpers/EnvHelper'
import {tx} from '@cityofzion/neon-core'
import {$} from '@/facade'
import {u} from '@cityofzion/neon-js'
import {FlamingoSwapHelper} from '@/libs/blockchain-services/helpers/FlamingoSwapHelper'
import {UNWRAPPING_FEE} from '@/libs/blockchain-services/constants/FlamingoSwapConstants'
import {
  BSNeo3NetworkId,
  SwapTokenToReceiveInvocationParams,
  SwapTokenToUseInvocationParams,
  Token,
  TokenInForTokenOutParams,
  TokenOutForTokenInParams,
  TransferArgs,
} from '@/libs/blockchain-services/types'
import {
  NeolineContractInvocationMulti,
  NeolineInvokeArguments,
} from '@/model/wallets/types/NeolineTypes'
import {NeoHelper} from '@/helpers/NeoHelper'
import {
  SwapServiceSwapToReceiveArgs,
  SwapServiceSwapToUseArgs,
} from '@/model/wallets/invocationsBuilder/GenericInvocationBuilder'
import {SwapParams} from '@/libs/blockchain-services/types'
import {Arg} from '@cityofzion/neon-dappkit-types'

export class NeolineInvocationBuilder implements GenericInvocationBuilder {
  swapInvocation(
    data:
      | SwapServiceSwapToReceiveArgs<BSNeo3NetworkId>
      | SwapServiceSwapToUseArgs<BSNeo3NetworkId>
  ): NeolineContractInvocationMulti {
    const {routePath, network, type} = data

    const [tokenToUse] = routePath
    const tokenToReceive = routePath[routePath.length - 1]

    const scriptHashes = FlamingoSwapHelper.getFlamingoSwapScriptHashes(network)

    const swapParams: SwapParams = {
      scriptHashes,
      tokenToUse,
      tokenToReceive,
    }

    if (data.type === 'swapTokenToReceive') {
      return this.swapTokenToReceiveInvocation({
        ...data,
        ...swapParams,
      })
    }

    return this.swapTokenToUseInvocation({
      ...data,
      ...swapParams,
    })
  }

  claimGasInvocation(address: string): NeolineContractInvocationMulti {
    return {
      invokeArgs: [
        {
          operation: 'transfer',
          scriptHash: EnvHelper.VUE_APP_NEO_SCRIPT_HASH,
          args: [
            {
              type: 'Address',
              value: String($.walletAdapter.address!),
            },
            {
              type: 'Address',
              value: String($.walletAdapter.address!),
            },
            {
              type: 'Integer',
              value: '0',
            },
            {
              type: 'Any',
              value: null,
            },
          ],
        },
      ],
      fee: '0',
      signers: [
        {
          account: NeoHelper.getScriptHashFromAddress($.walletAdapter.address!),
          scopes: tx.WitnessScope.CalledByEntry,
        },
      ],
    }
  }

  voteInvocation(candidatePublicKey: string): NeolineContractInvocationMulti {
    return {
      invokeArgs: [
        {
          operation: 'vote',
          scriptHash: EnvHelper.VUE_APP_NEO_SCRIPT_HASH,
          args: [
            {
              type: 'Hash160',
              value: $.walletAdapter.address!,
            },
            {
              type: 'PublicKey',
              value: candidatePublicKey,
            },
          ],
        },
      ],
      fee: '0',
      signers: [
        {
          account: NeoHelper.getScriptHashFromAddress($.walletAdapter.address!),
          scopes: tx.WitnessScope.CalledByEntry,
        },
      ],
    }
  }

  swapNeoUsingNeoProxyInvocation({
    address,
    neoAmountIn,
    gasAmountOutMin,
  }: SwapNeoUsingNeoProxyArgs): NeolineContractInvocationMulti {
    return {
      invokeArgs: [
        {
          operation: 'swapNeo',
          scriptHash: EnvHelper.VUE_APP_NEO_PROXY_SCRIPT_HASH,
          args: [
            {
              type: 'Hash160',
              value: address,
            },
            {
              type: 'Integer',
              value: neoAmountIn,
            },
            {
              type: 'Integer',
              value: gasAmountOutMin,
            },
          ],
        },
      ],
      /**
       * CU-86drvn85a: Investigate what is NeoLine's broadcastOverride and how to where to implement it on NeonDappkit
       *
       * Issue Comment: "The error was the amount of network fee  we were using. The network fee  can be wrong when you end up calling a verify.
       *                 To circumvent this problem you can add a hardcoded networkFeeOverride: 10000000(0.1 gas) on the ContractInvocationMulti."
       */
      networkFeeOverride: 10000000,
      signers: [
        {
          account: EnvHelper.VUE_APP_NEO_PROXY_SCRIPT_HASH,
          scopes: tx.WitnessScope.Global,
        },
        {
          account: NeoHelper.getScriptHashFromAddress(address),
          scopes: tx.WitnessScope.Global,
          allowedContracts: [
            EnvHelper.VUE_APP_NEO_PROXY_SCRIPT_HASH,
            NeoHelper.getScriptHashFromAddress(address),
          ],
        },
      ],
    }
  }

  swapToUseInvocation(
    data: SwapTokenToUseInvocationParams
  ): NeolineContractInvocationMulti {
    const {
      address,
      amountToUse,
      deadline,
      minimumReceived,
      network,
      routePath,
      scriptHashes,
      tokenToReceive,
      tokenToUse,
    } = data

    const tokenToUseOverrode = FlamingoSwapHelper.overrideToken(
      network,
      tokenToUse
    )
    const routePathOverrode = FlamingoSwapHelper.overrideRoutePath(
      network,
      routePath
    )

    const amountToUseFormatted = FlamingoSwapHelper.formatAmount(
      amountToUse,
      tokenToUseOverrode.decimals
    )
    const minimumReceivedFormatted = FlamingoSwapHelper.formatAmount(
      minimumReceived,
      tokenToReceive.decimals
    )

    const swapInvocation = this.swapTokenInForTokenOutContractInvocation({
      routerScriptHash: scriptHashes.flamingoSwapRouter,
      amountToUse: amountToUseFormatted,
      minimumReceived: minimumReceivedFormatted,
      senderAddress: address,
      deadline,
      args: this.mapRoutePathToArgs(routePathOverrode),
    })

    const swapAllowedContracts = [
      scriptHashes.flamingoSwapRouter,
      scriptHashes.flamingoFactory,
      scriptHashes.flamingoPairWhiteList,
    ]

    const allowedContracts = [
      ...new Set([
        ...swapAllowedContracts,
        ...routePath.map(token => token.hash),
      ]),
    ]

    return {
      invokeArgs: [swapInvocation],
      fee: '0',
      signers: [
        {
          account: NeoHelper.getScriptHashFromAddress($.walletAdapter.address!),
          scopes: tx.WitnessScope.CustomContracts,
          allowedContracts,
        },
      ],
    }
  }

  private swapTokenToUseInvocation(
    data: SwapTokenToUseInvocationParams
  ): NeolineContractInvocationMulti {
    const {network, routePath} = data

    if (FlamingoSwapHelper.isSwapWrappingNeo(network, routePath)) {
      return this.swapWrappingNeoInvocation(data)
    }

    if (FlamingoSwapHelper.isWrapNeo(network, routePath)) {
      return this.wrapNeoInvocation(data)
    }

    return this.swapToUseInvocation(data)
  }

  private swapWrappingNeoInvocation(
    data: SwapTokenToUseInvocationParams
  ): NeolineContractInvocationMulti {
    const {
      address,
      amountToUse,
      network,
      deadline,
      minimumReceived,
      routePath,
      scriptHashes,
      tokenToReceive,
      tokenToUse,
    } = data

    const tokenToUseOverrode = FlamingoSwapHelper.overrideToken(
      network,
      tokenToUse
    )
    const routePathOverrode = FlamingoSwapHelper.overrideRoutePath(
      network,
      routePath
    )

    const amountToUseFormatted = FlamingoSwapHelper.formatAmount(
      amountToUse,
      tokenToUseOverrode.decimals
    )
    const minimumReceivedFormatted = FlamingoSwapHelper.formatAmount(
      minimumReceived,
      tokenToReceive.decimals
    )

    const NEO = FlamingoSwapHelper.getFlamingoSwapToken(network, 'NEO')
    const bNEO = FlamingoSwapHelper.getFlamingoSwapToken(network, 'bNEO')

    const wrapInvocation = this.transferContractInvocation({
      contractHash: NEO.hash,
      tokenHash: bNEO.hash,
      senderAddress: address,
      amount: amountToUse,
    })
    const wrapAllowedContracts = [NEO.hash, bNEO.hash]

    const swapInvocation = this.swapTokenInForTokenOutContractInvocation({
      routerScriptHash: scriptHashes.flamingoSwapRouter,
      amountToUse: amountToUseFormatted,
      minimumReceived: minimumReceivedFormatted,
      senderAddress: address,
      deadline,
      args: this.mapRoutePathToArgs(routePathOverrode),
    })

    const swapAllowedContracts = [
      scriptHashes.flamingoSwapRouter,
      scriptHashes.flamingoFactory,
      scriptHashes.flamingoPairWhiteList,
    ]

    const allowedContracts = [
      ...new Set([
        ...swapAllowedContracts,
        ...wrapAllowedContracts,
        ...routePath.map(token => token.hash),
      ]),
    ]

    return {
      invokeArgs: [wrapInvocation, swapInvocation],
      fee: '0',
      signers: [
        {
          account: NeoHelper.getScriptHashFromAddress($.walletAdapter.address!),
          scopes: tx.WitnessScope.CustomContracts,
          allowedContracts,
        },
      ],
    }
  }

  private wrapNeoInvocation(
    data: SwapTokenToUseInvocationParams
  ): NeolineContractInvocationMulti {
    const {address, amountToUse, network} = data

    const NEO = FlamingoSwapHelper.getFlamingoSwapToken(network, 'NEO')
    const bNEO = FlamingoSwapHelper.getFlamingoSwapToken(network, 'bNEO')

    const wrapInvocation = this.transferContractInvocation({
      contractHash: NEO.hash,
      tokenHash: bNEO.hash,
      senderAddress: address,
      amount: amountToUse,
    })
    const wrapAllowedContracts = [NEO.hash, bNEO.hash]

    return {
      invokeArgs: [wrapInvocation],
      fee: '0',
      signers: [
        {
          account: NeoHelper.getScriptHashFromAddress($.walletAdapter.address!),
          scopes: tx.WitnessScope.CustomContracts,
          allowedContracts: wrapAllowedContracts,
        },
      ],
    }
  }

  private mapRoutePathToArgs(routePath: Token[]): Arg[] {
    return routePath.map(token => ({
      type: 'Hash160',
      value: token.hash,
    }))
  }

  private unwrapNeoInvocation(
    data: SwapTokenToReceiveInvocationParams
  ): NeolineContractInvocationMulti {
    const {address, amountToReceive, network, tokenToReceive} = data

    const tokenToReceiveOverrode = FlamingoSwapHelper.overrideToken(
      network,
      tokenToReceive
    )
    const amountToReceiveFormatted = FlamingoSwapHelper.formatAmount(
      amountToReceive,
      tokenToReceiveOverrode.decimals
    )

    const GAS = FlamingoSwapHelper.getFlamingoSwapToken(network, 'GAS')
    const bNEO = FlamingoSwapHelper.getFlamingoSwapToken(network, 'bNEO')

    const unwrappedAmount = FlamingoSwapHelper.getUnwrappedAmount(
      amountToReceiveFormatted
    )
    const unwrapInvocation = this.transferContractInvocation({
      contractHash: GAS.hash,
      tokenHash: bNEO.hash,
      senderAddress: address,
      amount: unwrappedAmount,
    })
    const unwrapAllowedContracts = [GAS.hash, bNEO.hash]

    return {
      invokeArgs: [unwrapInvocation],
      fee: '0',
      signers: [
        {
          account: NeoHelper.getScriptHashFromAddress($.walletAdapter.address!),
          scopes: tx.WitnessScope.CustomContracts,
          allowedContracts: unwrapAllowedContracts,
        },
      ],
    }
  }

  private swapTokenToReceiveInvocation(
    data: SwapTokenToReceiveInvocationParams
  ): NeolineContractInvocationMulti {
    const {network, routePath} = data

    if (FlamingoSwapHelper.isSwapUnwrappingNeo(network, routePath)) {
      return this.swapUnwrappingNeoInvocation(data)
    }

    if (FlamingoSwapHelper.isUnwrapNeo(network, routePath)) {
      return this.unwrapNeoInvocation(data)
    }

    return this.swapReceiveInvocation(data)
  }

  private swapUnwrappingNeoInvocation(
    data: SwapTokenToReceiveInvocationParams
  ): NeolineContractInvocationMulti {
    const {
      address,
      amountToReceive,
      deadline,
      maximumSelling,
      network,
      routePath,
      scriptHashes,
      tokenToReceive,
      tokenToUse,
    } = data

    const tokenToReceiveOverrode = FlamingoSwapHelper.overrideToken(
      network,
      tokenToReceive
    )
    const routePathOverrode = FlamingoSwapHelper.overrideRoutePath(
      network,
      routePath
    )

    const amountToReceiveFormatted = FlamingoSwapHelper.formatAmount(
      amountToReceive,
      tokenToReceiveOverrode.decimals
    )
    const maximumSellingFormatted = FlamingoSwapHelper.formatAmount(
      maximumSelling,
      tokenToUse.decimals
    )

    const amountToReceiveTransfer = u.BigInteger.fromNumber(
      Number(amountToReceiveFormatted) * UNWRAPPING_FEE
    ).toString()

    const GAS = FlamingoSwapHelper.getFlamingoSwapToken(network, 'GAS')
    const bNEO = FlamingoSwapHelper.getFlamingoSwapToken(network, 'bNEO')

    const unwrapInvocation = this.transferContractInvocation({
      contractHash: GAS.hash,
      tokenHash: bNEO.hash,
      senderAddress: address,
      amount: amountToReceiveTransfer,
    })
    const unwrapAllowedContracts = [GAS.hash, bNEO.hash]

    const swapInvocation = this.swapTokenOutForTokenInContractInvocation({
      routerScriptHash: scriptHashes.flamingoSwapRouter,
      senderAddress: address,
      amountToReceive: amountToReceiveFormatted,
      maximumSelling: maximumSellingFormatted,
      deadline,
      args: this.mapRoutePathToArgs(routePathOverrode),
    })
    const swapAllowedContracts = [
      scriptHashes.flamingoSwapRouter,
      scriptHashes.flamingoFactory,
      scriptHashes.flamingoPairWhiteList,
    ]

    const allowedContracts = [
      ...new Set([
        ...swapAllowedContracts,
        ...unwrapAllowedContracts,
        ...routePath.map(token => token.hash),
      ]),
    ]

    return {
      invokeArgs: [swapInvocation, unwrapInvocation],
      fee: '0',
      signers: [
        {
          account: NeoHelper.getScriptHashFromAddress($.walletAdapter.address!),
          scopes: tx.WitnessScope.CustomContracts,
          allowedContracts,
        },
      ],
    }
  }

  private swapReceiveInvocation(
    data: SwapTokenToReceiveInvocationParams
  ): NeolineContractInvocationMulti {
    const {
      address,
      amountToReceive,
      deadline,
      maximumSelling,
      network,
      routePath,
      scriptHashes,
      tokenToReceive,
      tokenToUse,
    } = data

    const tokenToReceiveOverrode = FlamingoSwapHelper.overrideToken(
      network,
      tokenToReceive
    )
    const routePathOverrode = FlamingoSwapHelper.overrideRoutePath(
      network,
      routePath
    )

    const amountToReceiveFormatted = FlamingoSwapHelper.formatAmount(
      amountToReceive,
      tokenToReceiveOverrode.decimals
    )
    const maximumSellingFormatted = FlamingoSwapHelper.formatAmount(
      maximumSelling,
      tokenToUse.decimals
    )

    const swapInvocation = this.swapTokenOutForTokenInContractInvocation({
      routerScriptHash: scriptHashes.flamingoSwapRouter,
      senderAddress: address,
      amountToReceive: amountToReceiveFormatted,
      maximumSelling: maximumSellingFormatted,
      deadline,
      args: this.mapRoutePathToArgs(routePathOverrode),
    })

    const swapAllowedContracts = [
      scriptHashes.flamingoSwapRouter,
      scriptHashes.flamingoFactory,
      scriptHashes.flamingoPairWhiteList,
    ]

    const allowedContracts = [
      ...new Set([
        ...swapAllowedContracts,
        ...routePath.map(token => token.hash),
      ]),
    ]

    return {
      invokeArgs: [swapInvocation],
      fee: '0',
      signers: [
        {
          account: NeoHelper.getScriptHashFromAddress($.walletAdapter.address!),
          scopes: tx.WitnessScope.CustomContracts,
          allowedContracts,
        },
      ],
    }
  }

  private swapTokenOutForTokenInContractInvocation({
    routerScriptHash,
    senderAddress,
    amountToReceive,
    maximumSelling,
    deadline,
    args,
  }: TokenOutForTokenInParams): NeolineInvokeArguments {
    return {
      operation: 'swapTokenOutForTokenIn',
      scriptHash: EnvHelper.VUE_APP_FLAMINGO_ROUTER_SCRIPT_HASH,
      args: [
        {
          type: 'Hash160',
          value: senderAddress,
        },
        {
          type: 'Integer',
          value: amountToReceive,
        },
        {
          type: 'Integer',
          value: maximumSelling,
        },
        {
          type: 'Array',
          value: args,
        },
        {
          type: 'Integer',
          value: deadline,
        },
      ],
    }
  }

  private swapTokenInForTokenOutContractInvocation({
    routerScriptHash,
    senderAddress,
    amountToUse,
    minimumReceived,
    deadline,
    args,
  }: TokenInForTokenOutParams): NeolineInvokeArguments {
    return {
      operation: 'swapTokenInForTokenOut',
      scriptHash: EnvHelper.VUE_APP_FLAMINGO_ROUTER_SCRIPT_HASH,
      args: [
        {
          type: 'Hash160',
          value: senderAddress,
        },
        {
          type: 'Integer',
          value: amountToUse,
        },
        {
          type: 'Integer',
          value: minimumReceived,
        },
        {
          type: 'Array',
          value: args,
        },
        {
          type: 'Integer',
          value: deadline,
        },
      ],
    }
  }

  private transferContractInvocation({
    contractHash,
    senderAddress,
    amount,
    tokenHash,
  }: TransferArgs): NeolineInvokeArguments {
    return {
      operation: 'transfer',
      scriptHash: contractHash,
      args: [
        {
          type: 'Address',
          value: senderAddress,
        },
        {
          type: 'Hash160',
          value: tokenHash,
        },
        {
          type: 'Integer',
          value: amount,
        },
        {
          type: 'Any',
          value: null,
        },
      ],
    }
  }

  private allowedContractsSwap(
    tokenInScriptHash: string,
    tokenOutScriptHash: string
  ): string[] {
    return [
      EnvHelper.VUE_APP_FLAMINGO_ROUTER_SCRIPT_HASH,
      EnvHelper.VUE_APP_FLAMINGO_FACTORY_SCRIPT_HASH,
      EnvHelper.VUE_APP_FLAMINGO_PAIR_WHITELIST_SCRIPT_HASH,
      tokenInScriptHash,
      tokenOutScriptHash,
    ]
  }

  private allowedContractsTransfer(): string[] {
    return [
      EnvHelper.VUE_APP_GAS_SCRIPT_HASH,
      EnvHelper.VUE_APP_FLP_BNEO_GAS_PAIR_SCRIPT_HASH,
      EnvHelper.VUE_APP_BNEO_SCRIPT_HASH,
    ]
  }
}
