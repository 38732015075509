import {Request} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {DappConnectionCollection} from '@/model/collection/DappConnectionCollection'

export class DappConnection implements IResource {
  idDappConnectionPk: number = 0

  title: string | null = null
  active: boolean | null = null

  get $id() {
    return this.idDappConnectionPk
  }

  set $id(val) {
    this.idDappConnectionPk = val
  }

  get $tag() {
    return String(this.title)
  }

  /**
   * Lists the instances of DappConnection
   */
  static async listDappConnection(params: any) {
    return await Request.get(`/client/dapp-connection`, {params})
      .name('listDappConnection')
      .as(DappConnectionCollection)
      .getData()
  }

  /**
   * Lists the instances of DappConnection to export as a file
   */
  static async listExportDappConnection(params: any) {
    return await Request.get(`/client/dapp-connection/export`, {params})
      .name('listExportDappConnection')
      .as(DappConnectionCollection)
      .getData()
  }

  /**
   * Gets a instance of a given ID of DappConnection
   */
  async getDappConnection(id: number) {
    return await Request.get(`/client/dapp-connection/${id}`)
      .name('getDappConnection')
      .as(this)
      .getData()
  }

  /**
   * Deletes a instance of a given ID of DappConnection
   */
  async removeDappConnection() {
    return await Request.delete(`/client/dapp-connection/${this.$id}`)
      .name('removeDappConnection')
      .asNumber()
      .getData()
  }

  /**
   * Persists a new instance of DappConnection. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistDappConnection() {
    return await Request.post(`/client/dapp-connection`, this)
      .name('persistDappConnection')
      .asNumber()
      .getData()
  }
}
