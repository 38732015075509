import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {Contract} from '@/model/resource/Contract'
import {DappCollection} from '@/model/collection/DappCollection'
import {plainToClass} from 'class-transformer'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import {Dapp} from '@/model/resource/Dapp'
import {PriceHistoryCollection} from '@/model/collection/PriceHistoryCollection'

@HttpExclude()
export class ContractCollection extends ExpansibleCollection<Contract> {
  resource?: IContractCollectionResourcesHolder
  editionList: Contract[] = []
  @RequestExpose() idDappFk: number[] = []
  @RequestExpose() idBlockchainVersionFk: number | null = null
  @RequestExpose() startDeploymentDate: string | null = null
  @RequestExpose() endDeploymentDate: string | null = null
  @RequestExpose() private: boolean | null = null
  @RequestExpose() active: boolean | null = true
  @RequestExpose() withDapp: boolean | null = null
  @RequestExpose() pricedToken: boolean | null = null
  @RequestExpose() token: boolean | null = null

  constructor() {
    super(Contract)
  }

  get filterCount(): number {
    return [this.idBlockchainVersionFk ? 1 : 0, this.search ? 1 : 0].reduce(
      (sum, value) => sum + value,
      0
    )
  }

  get dapp() {
    return this.resource?.collectionDapp.getManyIds(this.idDappFk) ?? null
  }

  set dapp(input) {
    this.idDappFk = input?.map(item => item?.$id) ?? []
  }

  get blockchainVersion() {
    return (
      this.resource?.collectionBlockchainVersion.getById(
        this.idBlockchainVersionFk
      ) ?? null
    )
  }

  set blockchainVersion(input) {
    this.idBlockchainVersionFk = input?.$id || null
  }

  async queryAsPage() {
    return this.listContract()
  }

  async listContract() {
    this.expandedItems = this.items
    return await Request.get(`/client/contract`, {params: this.params})
      .name('listContract')
      .as(this)
      .getResponse()
  }

  async listExportContract() {
    return await Request.get(`/client/contract/export`, {params: this.params})
      .name('listExportContract')
      .as(this)
      .getResponse()
  }

  async onAfterSerialization() {
    super.onAfterSerialization()

    this.addedItems.forEach(it => {
      it.dapp = plainToClass(Dapp, it.dapp)
    })
  }
}

export interface IContractCollectionResourcesHolder {
  collectionDapp: DappCollection
  collectionBlockchainVersion: BlockchainVersionCollection
  collectionPriceHistory: PriceHistoryCollection
}
