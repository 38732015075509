import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {ISocialTypeCollectionResourcesHolder} from '@/model/collection/SocialTypeCollection'
import {SocialType} from '@/model/resource/SocialType'

export class FilterSocialTypeSchema extends DefaultSchema
  implements ISocialTypeCollectionResourcesHolder {
  readonly name = 'FilterSocialType'

  readonly fieldSet: FieldSet<SocialType> = {}
}
