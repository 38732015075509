import {Request} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {GlobalCollection} from '@/model/collection/GlobalCollection'

export interface IBannerAction extends IResource {
  name: string
}

export class Global implements IResource {
  idGlobalPk: number = 0

  dappImageDimension: number | null = null
  boostedDappMaxsize: number | null = null
  minDappTransactionCountForTrending: number | null = null
  isSwapFeatureActive: boolean | null = null
  newContractMailingList: string | null = null
  uploadImageAllowedFiles: string[] = []
  socialTypeIconDimension: number = 80

  static get bannerWebsiteSections() {
    return [
      {
        $id: 1,
        $tag: 'Home',
      },
      {
        $id: 2,
        $tag: 'Wallet',
      },
      {
        $id: 3,
        $tag: 'Home and Wallet',
      },
    ]
  }

  static get bannerActions(): IBannerAction[] {
    return [
      {
        $id: 1,
        $tag: 'Do nothing',
        name: 'do-nothing',
      },
      {
        $id: 2,
        $tag: 'Open Gleeder Modal',
        name: 'gleeder',
      },
      {
        $id: 3,
        $tag: 'Open External Link',
        name: 'external-link',
      },
    ]
  }

  get $id() {
    return this.idGlobalPk
  }

  set $id(val) {
    this.idGlobalPk = val
  }

  get $tag() {
    return String(this.$id)
  }

  /**
   * Lists the instances of Global
   */
  static async listGlobal(params: any) {
    return await Request.get(`/client/global`, {params})
      .name('listGlobal')
      .as(GlobalCollection)
      .getData()
  }

  /**
   * Lists the instances of Global to export as a file
   */
  static async listExportGlobal(params: any) {
    return await Request.get(`/client/global/export`, {params})
      .name('listExportGlobal')
      .as(GlobalCollection)
      .getData()
  }

  /**
   * Gets a instance of a given ID of Global
   */
  static async getGlobal() {
    return await Request.get(`/client/global`)
      .name('getGlobal')
      .as(this)
      .getData()
  }

  static async toggleSwapFeature(active: boolean) {
    return await Request.put(
      '/client/global/setSwapFeatureEnabled',
      undefined,
      {
        params: {active},
      }
    )
      .name('toggleSwapFeature')
      .asBoolean()
      .getData()
  }

  /**
   * Persists a new instance of Global. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistGlobal() {
    return await Request.post(`/client/global`, this)
      .name('persistGlobal')
      .asNumber()
      .getData()
  }
}
