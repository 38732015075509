import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {Exchange} from '@/model/resource/Exchange'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import FileManagerWithList from '@/components/FileManagerWithList.vue'
import {$} from '@/facade'

export class InputExchangeSchema extends DefaultSchema {
  collectionBlockchain = new BlockchainVersionCollection().noPagination()

  readonly name = 'InputExchange'

  readonly fieldSet: FieldSet<Exchange> = {
    title: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 63,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    websiteUrl: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 127,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    imageUrl: (schema): FieldComponent => ({
      is: FileManagerWithList,
      bind: {
        folder: 'exchange',
        compressDimension: $.auth.global.dappImageDimension,
        crop: false,
        class: 'scoped__image-url',
        required: true,
        validation: 'required',
      },
    }),
    order: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    blockchainVersion: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        label: this.translateFrom(schema.fieldName),
        items: this.collectionBlockchain.items,
        required: true,
        inputClass: 'multiselect--hide-checkbox',
        validation: 'required',
      },
    }),
    supportsNeo: (schema): FieldComponent => ({
      is: Component.InputCheckbox,
      bind: {
        label: this.translateFrom(schema.fieldName),
      },
    }),
    supportsGas: (schema): FieldComponent => ({
      is: Component.InputCheckbox,
      bind: {
        label: this.translateFrom(schema.fieldName),
      },
    }),
    supportsNep17: (schema): FieldComponent => ({
      is: Component.InputCheckbox,
      bind: {
        label: this.translateFrom(schema.fieldName),
      },
    }),
  }
}
