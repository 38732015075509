import {$} from '@/facade'
import {Request, ResponseExclude} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {UserCollection} from '@/model/collection/UserCollection'

export class User implements IResource {
  idUserPk: number = 0

  email: string | null = null
  fullName: string | null = null

  @ResponseExclude()
  password: string | null = null

  get $id() {
    return this.idUserPk
  }

  set $id(val) {
    this.idUserPk = val
  }

  get $tag() {
    return String(this.email)
  }

  /**
   * Lists the instances of User
   */
  static async listUser(params: any) {
    return await Request.get(`/client/user`, {params})
      .name('listUser')
      .as(UserCollection)
      .getData()
  }

  /**
   * Lists the instances of User to export as a file
   */
  static async listExportUser(params: any) {
    return await Request.get(`/client/user/export`, {params})
      .name('listExportUser')
      .as(UserCollection)
      .getData()
  }

  /**
   * Gets a instance of a given ID of User
   */
  async getUser(id: number) {
    return await Request.get(`/client/user/${id}`)
      .name('getUser')
      .as(this)
      .getData()
  }

  /**
   * Persists a new instance of User. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistUser() {
    const request = $.utils.clone(this)
    request.password = $.utils.sha256(this.password)
    return await Request.post(`/client/user`, request)
      .name('persistUser')
      .asNumber()
      .getData()
  }

  /**
   * Deletes a instance of a given ID of a User
   */
  async removeUser() {
    return await Request.delete(`/client/user/${this.$id}`)
      .name('removeUser')
      .asNumber()
      .getData()
  }
}
