










































































import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import {Fragment} from 'vue-fragment'
import {TokensTableItem} from '@/model/resource/TokensTableItem'
import HomepageCardHeader from '@/components/cards/homepage/HomepageCardHeader.vue'
import HomepageCardContent from '@/components/cards/homepage/HomepageCardContent.vue'

type TrendingTokens = {
  title: string
  timeTitle: string
  seeAll: string
  tokens: Tokens[]
}

type Tokens = {
  title: string | null | undefined
  imageUrl: string | null | undefined
  priceTitle: string
  priceValue: string
  volumeTitle: string
  volumeValue: number | null
}

@Component({
  components: {
    HomepageCardHeader,
    HomepageCardContent,
    PercentageProgress,
    Fragment,
  },
})
export default class TrendingTokensCard extends Vue {
  @Prop({type: Array, required: true}) tokens!: TokensTableItem[]

  items: TrendingTokens = {
    title: this.$translate('components.cards.TrendingTokensCard.title'),
    timeTitle: this.$translate('components.cards.TrendingTokensCard.timeTitle'),
    seeAll: this.$translate('components.cards.TrendingTokensCard.seeAll'),
    tokens: [],
  }

  async mounted() {
    await this.getData()
  }

  private async getData() {
    for (const token of this.tokens) {
      this.items.tokens.push({
        title: token.symbol,
        imageUrl: token.imageUrl,
        priceTitle: this.$translate(
          'components.cards.TrendingTokensCard.priceTitle'
        ),
        priceValue: token.marketInformation?.currentPrice?.toString() || '',
        volumeTitle: this.$translate(
          'components.cards.TrendingTokensCard.volumeTitle'
        ),
        volumeValue:
          Number(token.marketInformation?.priceVariationLastDay) || 0,
      })
    }
  }
}
