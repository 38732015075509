import MAINNET_TOKENS from './mainnet.json'
import {Token, Network, BSNeo3NetworkId} from '@/libs/blockchain-services/types'

export const EXTRA_TOKENS_BY_NETWORK_ID: Partial<Record<
  BSNeo3NetworkId,
  Token[]
>> = {
  mainnet: MAINNET_TOKENS,
}

export const RPC_LIST_BY_NETWORK_ID: Partial<Record<
  BSNeo3NetworkId,
  string[]
>> = {
  mainnet: [
    'https://mainnet1.neo.coz.io:443',
    'https://mainnet4.neo.coz.io:443',
    'http://seed1.neo.org:10332',
    'http://seed2.neo.org:10332',
    'https://mainnet2.neo.coz.io:443',
    'https://mainnet5.neo.coz.io:443',
    'https://mainnet3.neo.coz.io:443',
    'https://rpc10.n3.nspcc.ru:10331',
    'https://neo1-nodes.ghostmarket.io:443',
  ],
  testnet: [
    'https://testnet1.neo.coz.io:443',
    'https://testnet2.neo.coz.io:443',
    'https://rpc.t5.n3.nspcc.ru:20331',
    'http://seed1t5.neo.org:20332',
    'http://seed2t5.neo.org:20332',
    'http://seed3t5.neo.org:20332',
    'http://seed4t5.neo.org:20332',
    'http://seed5t5.neo.org:20332',
  ],
}

export const MAINNET_NETWORK_IDS: BSNeo3NetworkId[] = ['mainnet']
export const TESTNET_NETWORK_IDS: BSNeo3NetworkId[] = ['testnet']
export const ALL_NETWORK_IDS: BSNeo3NetworkId[] = [
  ...MAINNET_NETWORK_IDS,
  ...TESTNET_NETWORK_IDS,
]

export const MAINNET_NETWORKS: Network<BSNeo3NetworkId>[] = [
  {
    id: 'mainnet',
    name: 'Mainnet',
    url: RPC_LIST_BY_NETWORK_ID['mainnet']![0],
  },
]

export const TESTNET_NETWORKS: Network<BSNeo3NetworkId>[] = [
  {
    id: 'testnet',
    name: 'Testnet',
    url: RPC_LIST_BY_NETWORK_ID['testnet']![0],
  },
]

export const ALL_NETWORKS: Network<BSNeo3NetworkId>[] = [
  ...MAINNET_NETWORKS,
  ...TESTNET_NETWORKS,
]

export const DEFAULT_NETWORK = MAINNET_NETWORKS[0]

export const NEO_NS_HASH = '0x50ac1c37690cc2cfc594472833cf57505d5f46de'

export const DEFAULT_BIP44_DERIVATION_PATH = "m/44'/888'/0'/0/?"
