import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {SupportedToken} from '@/model/resource/SupportedToken'

export class ListSupportedTokenSchema extends DefaultSchema {
  readonly name = 'ListSupportedToken'

  readonly fieldSet: FieldSet<SupportedToken> = {
    order: (): FieldComponent => ({
      is: Component.Render,
    }),
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
    imageUrl: (schema): FieldComponent => ({
      is: Component.RenderImage,
      bind: {
        src: schema.model.imageUrl,
        alt: this.translateFrom(schema.fieldName),
        innerClass: 'h-50 max-w-xs md:max-w-md lg:max-w-lg',
      },
    }),
  }
}
