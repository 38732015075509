
























































































































































































































































































































































































































































import {Component, Prop, Watch, Mixins} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import AccordionTitle from '@/components/accordion/AccordionTitle.vue'
import AccordionItem from '@/components/accordion/AccordionItem.vue'

@Component({})
export default class Sidebar extends Mixins(MixinScreenSize) {
  menu = false

  get sidebarClass() {
    return {
      'sidebar--collapse': !this.genericMenu,
    }
  }

  get genericMenu() {
    return this.menu || !this.isDesktop
  }

  get desktopMenu() {
    return this.menu || this.isDesktop
  }

  created() {
    const menu = localStorage.getItem('menu')

    if (this.isDesktop && menu !== null) {
      this.menu = Boolean(Number(menu))
    } else if (this.isDesktop && menu === null) {
      this.menu = true
    }
  }

  @Watch('menu')
  menuEvent(menu: boolean) {
    const val = menu ? '1' : '0'
    localStorage.setItem('menu', val)
  }

  @Watch('genericMenu', {immediate: true})
  genericMenuEvent(menu: boolean) {
    this.$emit('toggle', menu)
  }

  toggleMenu() {
    this.menu = !this.menu
  }

  menuOff() {
    if (!this.isDesktop) {
      this.menu = false
    }
  }

  logout() {
    this.$auth.signOut()
    this.$nav.replace('/sign-in')
  }
}
