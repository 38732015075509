import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {IBlockchainVersionCollectionResourcesHolder} from '@/model/collection/BlockchainVersionCollection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'

export class FilterBlockchainVersionSchema extends DefaultSchema
  implements IBlockchainVersionCollectionResourcesHolder {
  readonly name = 'FilterBlockchainVersion'

  readonly fieldSet: FieldSet<BlockchainVersion> = {}
}
