import {$} from '@/facade'
import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseSerialize,
} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {DappSocial} from '@/model/resource/DappSocial'
import {DappCollection} from '@/model/collection/DappCollection'
import {SocialTypeCollection} from '@/model/collection/SocialTypeCollection'
import {plainToClass} from 'class-transformer'
import {Dapp} from '@/model/resource/Dapp'

@HttpExclude()
export class DappSocialCollection extends PageCollection<DappSocial> {
  resource?: IDappSocialCollectionResourcesHolder
  editionList: DappSocial[] = []
  @RequestExpose() idDappFk: number[] = []

  constructor() {
    super(DappSocial)
  }

  get dapp() {
    return this.resource?.collectionDapp.getManyIds(this.idDappFk) ?? null
  }

  set dapp(input) {
    this.idDappFk = input?.map(item => item?.$id) ?? []
  }

  queryAsPage() {
    return this.listDappSocial()
  }

  async listDappSocial() {
    const response = await Request.get(`/client/dapp-social`, {
      params: this.params,
    })
      .name('listDappSocial')
      .as(this)
      .getResponse()

    this.items.forEach(it => (it.dapp = plainToClass(Dapp, it.dapp)))

    return response
  }

  async listExportDappSocial() {
    const response = await Request.get(`/client/dapp-social/export`, {
      params: this.params,
    })
      .name('listExportDappSocial')
      .as(this)
      .getResponse()

    this.items.forEach(it => (it.dapp = plainToClass(Dapp, it.dapp)))

    return response
  }
}

export interface IDappSocialCollectionResourcesHolder {
  collectionDapp: DappCollection
  collectionSocialType: SocialTypeCollection
}
