import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {BoostedDapp} from '@/model/resource/BoostedDapp'

export class ListBoostedDappSchema extends DefaultSchema {
  readonly name = 'ListBoostedDapp'

  readonly fieldSet: FieldSet<BoostedDapp> = {
    dapp: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.dapp?.$tag,
      },
    }),
    order: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
