import {$} from '@/facade'
import {Request} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {CategoryCollection} from '@/model/collection/CategoryCollection'

export class Category implements IResource {
  idCategoryPk: number = 0

  title: string | null = null
  active: boolean | null = null

  get $id() {
    return this.idCategoryPk
  }

  set $id(val) {
    this.idCategoryPk = val
  }

  get $tag() {
    return String(this.title)
  }

  /**
   * Lists the instances of Category
   */
  static async listCategory(params: any) {
    return await Request.get(`/client/category`, {params})
      .name('listCategory')
      .as(CategoryCollection)
      .getData()
  }

  /**
   * Lists the instances of Category to export as a file
   */
  static async listExportCategory(params: any) {
    return await Request.get(`/client/category/export`, {params})
      .name('listExportCategory')
      .as(CategoryCollection)
      .getData()
  }

  /**
   * Gets a instance of a given ID of Category
   */
  async getCategory(id: number) {
    return await Request.get(`/client/category/${id}`)
      .name('getCategory')
      .as(this)
      .getData()
  }

  /**
   * Deletes a instance of a given ID of Category
   */
  async removeCategory() {
    return await Request.delete(`/client/category/${this.$id}`)
      .name('removeCategory')
      .asNumber()
      .getData()
  }

  /**
   * Persists a new instance of Category. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistCategory() {
    return await Request.post(`/client/category`, this)
      .name('persistCategory')
      .asNumber()
      .getData()
  }
}
