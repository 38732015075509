import {$} from '@/facade'
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {Dapp} from '@/model/resource/Dapp'
import {BoostedDappCollection} from '@/model/collection/BoostedDappCollection'

export class BoostedDapp implements IResource {
  @ResponseSerialize(Dapp)
  dapp: Dapp | null = null

  order: number | null = null

  get $id() {
    return this.dapp?.$id ?? 0
  }

  set $id(val) {
    if (this.dapp) {
      this.dapp.$id = val
    }
  }

  get $tag() {
    return String(this.$id)
  }

  get idDappFk() {
    if (!this.dapp || !this.dapp?.idDappPk) return 0
    return this.dapp.$id
  }

  set idDappFk(val) {
    if (!this.dapp) this.dapp = new Dapp()
    this.dapp.$id = val
  }

  /**
   * Gets a instance of a given ID of BoostedDapp
   */
  async getBoostedDapp(id: number) {
    return await Request.get(`/client/boosted-dapp/${id}`)
      .name('getBoostedDapp')
      .as(this)
      .getData()
  }

  /**
   * Persists a new instance of BoostedDapp. Use ID = 0 to create a new one
   */
  async persistBoostedDapp() {
    return await Request.post(`/client/boosted-dapp`, this)
      .name('persistBoostedDapp')
      .asNumber()
      .getData()
  }

  /**
   * Persists a editing BoostedDapp.
   */
  async persistEditingBoostedDapp() {
    return await Request.put(`/client/boosted-dapp`, this)
      .name('persistBoostedDapp')
      .asNumber()
      .getData()
  }

  /**
   * Deletes a instance of BoostedDapp from an id of Dapp
   */
  async removeBoostedDapp() {
    return await Request.delete(`/client/boosted-dapp/${this.idDappFk}`)
      .name('removeBoostedDapp')
      .asNumber()
      .getData()
  }

  /**
   * Increments the order of BoostedDapp
   */
  async changeOrder() {
    return await Request.post(`/client/boosted-dapp/${this.$id}/order`, this)
      .name('changeOrder')
      .asNumber()
      .getData()
  }
}
