import {Request} from '@simpli/serialized-request'
import {Item} from 'rss-parser'
import {$} from '@/facade'

export class LatestNewsItem implements Item {
  [key: string]: any

  img: string | null = ''
  description: string[] = []
  title?: string = ''
  link?: string = ''
  guid?: string = ''
  pubDate?: string = ''
  isoDate?: string = ''

  /**
   * Lists the instances of LatestNewsItem
   */
  static async listLatestNewsItem() {
    return await Request.get($.env.NEO_FEED_URL)
      .name('listLatestNewsItem')
      .as(String)
      .getResponse()
  }
}
