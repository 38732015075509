import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet} from '@simpli/meta-schema'
import {Repository} from '@/model/resource/Repository'

export class ExportRepositorySchema extends DefaultSchema {
  readonly name = 'ExportRepository'

  readonly fieldSet: FieldSet<Repository> = {
    idRepositoryPk: schema => schema.model.idRepositoryPk,
    organization: schema => schema.model.organization?.$tag ?? null,
    labelsTokenized: schema => schema.model.labelsTokenized,
    title: schema => schema.model.title,
    description: schema => schema.model.description,
    colorHex: schema => schema.model.colorHex,
    repositoryUrl: schema => schema.model.repositoryUrl,
  }
}
