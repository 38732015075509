import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {Banner} from '@/model/resource/Banner'

export class ListBannerSchema extends DefaultSchema {
  readonly name = 'ListBanner'

  readonly fieldSet: FieldSet<Banner> = {
    order: (): FieldComponent => ({
      is: Component.Render,
    }),
    websiteSection: (): FieldComponent => ({
      is: Component.Render,
    }),
    imageUrl: (schema): FieldComponent => ({
      is: Component.RenderImage,
      bind: {
        src: schema.model.imageUrl,
        alt: this.translateFrom(schema.fieldName),
        innerClass: 'h-50 max-w-xs md:max-w-md lg:max-w-lg',
      },
    }),
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
    action: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        model: schema.model.actionAsIBannerAction,
      },
    }),
    url: (schema): FieldComponent => ({
      is: Component.RenderAnchor,
      bind: {
        href: schema.model.url,
        label: schema.model.url,
        target: '_blank',
      },
    }),
    description: (): FieldComponent => ({
      is: Component.Render,
    }),
    type: (): FieldComponent => ({
      is: Component.Render,
    }),
    tags: (): FieldComponent => ({
      is: Component.Render,
    }),
    visibleStartDatetime: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: $.filter.datetime(schema.model.visibleStartDatetime),
      },
    }),
    visibleEndDatetime: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: $.filter.datetime(schema.model.visibleEndDatetime),
      },
    }),
  }
}
