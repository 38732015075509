import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {Explorer} from '@/model/resource/Explorer'
import FileManagerWithList from '@/components/FileManagerWithList.vue'
import {$} from '@/facade'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'

export class InputExplorerSchema extends DefaultSchema {
  collectionBlockchain = new BlockchainVersionCollection().noPagination()

  readonly name = 'InputExplorer'

  readonly fieldSet: FieldSet<Explorer> = {
    title: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 63,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    description: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'textarea',
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
        class: 'col-span-2',
        rows: 5,
      },
    }),
    websiteUrl: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 127,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    imageUrl: (schema): FieldComponent => ({
      is: FileManagerWithList,
      bind: {
        folder: 'explorer',
        compressDimension: $.auth.global.dappImageDimension,
        crop: false,
        class: 'scoped__image-url',
      },
    }),
    order: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    blockchainVersion: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        label: this.translateFrom(schema.fieldName),
        items: this.collectionBlockchain.items,
        required: true,
        inputClass: 'multiselect--hide-checkbox',
        validation: 'required',
      },
    }),
  }
}
