import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {Contract} from '@/model/resource/Contract'

export class ContractNep17 implements IResource {
  @ResponseSerialize(Contract)
  contract: Contract | null = null

  name: string | null = null
  symbol: string | null = null
  lastTotalSupply: number | null = 0
  decimals: number | null = 0
  order: number | null = 0
  isSwappable: boolean | null = null

  get $id() {
    if (!this.contract) return 0
    return this.contract.idContractPk
  }

  set $id(val) {
    if (!this.contract) {
      this.contract = new Contract()
    }
    this.contract.idContractPk = val
  }

  get $tag() {
    return String(this.name)
  }

  /**
   * Increments the order of ContractNep17
   */
  async changeOrder() {
    return await Request.post(`/client/contract-nep-17/${this.$id}/order`, this)
      .name('changeOrder')
      .asNumber()
      .getData()
  }
}
