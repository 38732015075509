import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet} from '@simpli/meta-schema'
import {DappSocial} from '@/model/resource/DappSocial'

export class ExportDappSocialSchema extends DefaultSchema {
  readonly name = 'ExportDappSocial'

  readonly fieldSet: FieldSet<DappSocial> = {
    dapp: schema => schema.model.dapp?.$tag ?? null,
    socialType: schema => schema.model.socialType?.$tag ?? null,
    url: schema => schema.model.url,
  }
}
