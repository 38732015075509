import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import {Contract} from '@/model/resource/Contract'
import ContractCellRender from '@/components/render/ContractCellRender.vue'
import CopyRender from '@/components/render/CopyRender.vue'
import DappCellRender from '@/components/render/DappCellRender.vue'
import * as Component from '@simpli/vue-render-schema'

export class SimpleListContractSchema extends DefaultSchema {
  readonly name = 'ListContract'

  readonly fieldSet: FieldSet<Contract> = {
    rank: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.rank || '-',
      },
    }),
    title: (schema): FieldComponent => ({
      is: ContractCellRender,
      bind: {
        contract: schema.model,
      },
    }),
    hash: (): FieldComponent => ({
      is: CopyRender,
    }),
    dapp: (schema): FieldComponent => ({
      is: DappCellRender,
      bind: {
        contract: schema.model,
      },
    }),
  }
}
