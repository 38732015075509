import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {ExplorerCollection} from '@/model/collection/ExplorerCollection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'

export class Explorer implements IResource {
  idExplorerPk: number = 0

  active: boolean | null = null

  title: string | null = null
  description: string | null = null
  websiteUrl: string | null = null
  imageUrl: string | null = null
  order: number | null = null
  @ResponseSerialize(BlockchainVersion)
  blockchainVersion: BlockchainVersion[] = []

  get $id() {
    return this.idExplorerPk
  }

  set $id(val) {
    this.idExplorerPk = val
  }

  get $tag() {
    return String(this.title)
  }

  get blockchainVersionTitles() {
    return this.blockchainVersion?.map(resource => resource.title).join(', ')
  }

  /**
   * Lists the instances of Explorer
   */
  static async listExplorer(params: any) {
    return await Request.get(`/client/explorer`, {params})
      .name('listExplorer')
      .as(ExplorerCollection)
      .getData()
  }

  /**
   * Gets a instance of a given ID of Explorer
   */
  async getExplorer(id: number) {
    return await Request.get(`/client/explorer/${id}`)
      .name('getExplorer')
      .as(this)
      .getData()
  }

  /**
   * Deletes a instance of a given ID of Explorer
   */
  async removeExplorer() {
    return await Request.delete(`/client/explorer/${this.$id}`)
      .name('removeExplorer')
      .asNumber()
      .getData()
  }

  /**
   * Persists a new instance of Explorer. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistExplorer() {
    return await Request.post(`/client/explorer`, this)
      .name('persistExplorer')
      .asNumber()
      .getData()
  }

  /**
   * Increments the order of Explorer
   */
  async changeOrder() {
    return await Request.post(`/client/explorer/${this.$id}/order`, this)
      .name('changeOrder')
      .asNumber()
      .getData()
  }
}
