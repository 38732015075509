import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {ITokenCollectionResourcesHolder} from '@/model/collection/TokenCollection'
import {Token} from '@/model/resource/Token'

export class FilterTokenSchema extends DefaultSchema
  implements ITokenCollectionResourcesHolder {
  readonly name = 'FilterToken'

  readonly fieldSet: FieldSet<Token> = {}
}
