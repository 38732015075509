import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {IExplorerCollectionResourcesHolder} from '@/model/collection/ExplorerCollection'
import {Explorer} from '@/model/resource/Explorer'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'

export class FilterExplorerSchema extends DefaultSchema
  implements IExplorerCollectionResourcesHolder {
  collectionBlockchainVersion = new BlockchainVersionCollection().noPagination()

  readonly name = 'FilterExplorer'

  readonly fieldSet: FieldSet<Explorer> = {
    explorerBlockchainVersion: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        items: this.collectionBlockchainVersion.items,
        multiple: true,
        closeOnSelect: true,
        preserveSearch: true,
        placeholder: this.translateFrom(schema.fieldName),
        trackBy: '$id',
        preselectFirst: true,
      },
    }),
  }
}
