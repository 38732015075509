import {$} from '@/facade'
import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseSerialize,
} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {DeveloperAid} from '@/model/resource/DeveloperAid'

@HttpExclude()
export class DeveloperAidCollection extends PageCollection<DeveloperAid> {
  resource?: IDeveloperAidCollectionResourcesHolder

  constructor() {
    super(DeveloperAid)
  }

  queryAsPage() {
    return this.listDeveloperAid()
  }

  async listDeveloperAid() {
    return await Request.get(`/client/developer-aid`, {params: this.params})
      .name('listDeveloperAid')
      .as(this)
      .getResponse()
  }

  async listExportDeveloperAid() {
    return await Request.get(`/client/developer-aid/export`, {
      params: this.params,
    })
      .name('listExportDeveloperAid')
      .as(this)
      .getResponse()
  }
}

export interface IDeveloperAidCollectionResourcesHolder {}
