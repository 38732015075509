import {$} from '@/facade'
import {Request} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {DeveloperAidCollection} from '@/model/collection/DeveloperAidCollection'

export class DeveloperAid implements IResource {
  idDeveloperAidPk: number = 0

  imageUrl: string | null = null
  callToActionText: string | null = null
  actionUrl: string | null = null
  description: string | null = null

  get $id() {
    return this.idDeveloperAidPk
  }

  set $id(val) {
    this.idDeveloperAidPk = val
  }

  get $tag() {
    return String(this.$id)
  }

  /**
   * Lists the instances of DeveloperAid
   */
  static async listDeveloperAid(params: any) {
    return await Request.get(`/client/developer-aid`, {params})
      .name('listDeveloperAid')
      .as(DeveloperAidCollection)
      .getData()
  }

  /**
   * Lists the instances of DeveloperAid to export as a file
   */
  static async listExportDeveloperAid(params: any) {
    return await Request.get(`/client/developer-aid/export`, {params})
      .name('listExportDeveloperAid')
      .as(DeveloperAidCollection)
      .getData()
  }

  /**
   * Gets a instance of a given ID of DeveloperAid
   */
  async getDeveloperAid() {
    return await Request.get(`/client/developer-aid`)
      .name('getDeveloperAid')
      .as(this)
      .getData()
  }

  /**
   * Persists a new instance of DeveloperAid. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistDeveloperAid() {
    return await Request.post(`/client/developer-aid`, this)
      .name('persistDeveloperAid')
      .asNumber()
      .getData()
  }
}
