import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet} from '@simpli/meta-schema'
import {Dapp} from '@/model/resource/Dapp'

export class ExportDappSchema extends DefaultSchema {
  readonly name = 'ExportDapp'

  readonly fieldSet: FieldSet<Dapp> = {
    idDappPk: schema => schema.model.idDappPk,
    imageUrl: schema => schema.model.imageUrl,
    categoryNamesCache: schema => schema.model.categoryNamesCache,
    title: schema => schema.model.title,
    shortDescription: schema => schema.model.shortDescription,
    websiteUrl: schema => schema.model.websiteUrl,
    companyName: schema => schema.model.companyName,
    active: schema => $.filter.bool(schema.model.active),
    blockchainVersion: schema => schema.model.blockchainVersionTitle,
    description: schema => schema.model.description,
  }
}
