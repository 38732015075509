import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {DappSocial} from '@/model/resource/DappSocial'
import {DappCollection} from '@/model/collection/DappCollection'
import {SocialTypeCollection} from '@/model/collection/SocialTypeCollection'

export class InputDappSocialSchema extends DefaultSchema {
  collectionDapp = new DappCollection().noPagination()
  collectionSocialType = new SocialTypeCollection().noPagination()

  readonly name = 'InputDappSocial'

  readonly fieldSet: FieldSet<DappSocial> = {
    dapp: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        label: this.translateFrom(schema.fieldName),
        items: this.collectionDapp.items,
        class: 'hidden',
      },
    }),
    socialType: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        label: this.translateFrom(schema.fieldName),
        items: this.collectionSocialType.items,
      },
    }),
    url: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 255,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required|url',
      },
    }),
  }
}
