import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {Contract} from '@/model/resource/Contract'
import {$} from '@/facade'
import RenderCopy from '@/components/RenderCopy.vue'

export class ListContractSchema extends DefaultSchema {
  readonly name = 'ListContract'

  readonly fieldSet: FieldSet<Contract> = {
    idContractPk: (): FieldComponent => ({
      is: Component.Render,
    }),
    imageUrl: (schema): FieldComponent => ({
      is: Component.RenderImage,
      bind: {
        src: schema.model.imageUrl,
        alt: this.translateFrom(schema.fieldName),
        innerClass: 'w-32',
      },
    }),
    blockchainVersion: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.blockchainVersion?.title,
      },
    }),
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
    deploymentDate: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: $.filter.datetime(schema.model.deploymentDate),
      },
    }),
    hash: (): FieldComponent => ({
      is: RenderCopy,
    }),
    dapp: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.dapp?.title,
      },
    }),
  }
}
