import {$} from '@/facade'
import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseSerialize,
} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {SocialType} from '@/model/resource/SocialType'

@HttpExclude()
export class SocialTypeCollection extends PageCollection<SocialType> {
  resource?: ISocialTypeCollectionResourcesHolder

  constructor() {
    super(SocialType)
  }

  queryAsPage() {
    return this.listSocialType()
  }

  async listSocialType() {
    return await Request.get(`/client/social-type`, {params: this.params})
      .name('listSocialType')
      .as(this)
      .getResponse()
  }

  async listExportSocialType() {
    return await Request.get(`/client/social-type/export`, {
      params: this.params,
    })
      .name('listExportSocialType')
      .as(this)
      .getResponse()
  }
}

export interface ISocialTypeCollectionResourcesHolder {}
