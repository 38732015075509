import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {ContractCounter} from '@/model/resource/ContractCounter'
import {DappDetailedGraphPeriod} from '@/enums/DappDetailedGraphPeriod'

@HttpExclude()
export class ContractCounterCollection extends ExpansibleCollection<
  ContractCounter
> {
  resource?: IContractCounterCollectionResourcesHolder

  @RequestExpose() idContractFk: number[] = []
  @RequestExpose() interval: DappDetailedGraphPeriod =
    DappDetailedGraphPeriod.SEVEN_DAYS
  @RequestExpose() startDate: string | null = null
  @RequestExpose() endDate: string | null = null
  @RequestExpose() isReference: boolean = false

  constructor() {
    super(ContractCounter)
  }

  async listDailyContractCounter(): Promise<any> {
    return await Request.get(`/client/contract-counter/daily`, {
      params: this.params,
    })
      .name('listDailyContractCounter')
      .as(this)
      .getData()
  }

  async listHourlyContractCounter(): Promise<any> {
    return await Request.get(`/client/contract-counter/hourly`, {
      params: this.params,
    })
      .name('listHourlyContractCounter')
      .as(this)
      .getData()
  }

  queryAsPage(): Promise<any> {
    return this.listHourlyContractCounter()
  }
}

export interface IContractCounterCollectionResourcesHolder {}
