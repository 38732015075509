import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {TokenPriced} from '@/model/resource/TokenPriced'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'

@HttpExclude()
export class TokenPricedCollection extends ExpansibleCollection<TokenPriced> {
  resource?: ITokenPricedCollectionResourcesHolder

  @RequestExpose() idBlockchainVersionFk: number | null = null
  @RequestExpose() active: boolean | null = null

  constructor() {
    super(TokenPriced)
  }

  set blockchainVersion(input: BlockchainVersion | null) {
    this.idBlockchainVersionFk = input?.$id || null
  }

  queryAsPage() {
    return this.listTokenPriced()
  }

  async listTokenPriced() {
    return await Request.get(`/client/token-priced`, {
      params: this.params,
    })
      .name('listTokenPriced')
      .as(this)
      .getResponse()
  }
}

export interface ITokenPricedCollectionResourcesHolder {
  collectionBlockchainVersion: BlockchainVersionCollection
}
