import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {DappConnection} from '@/model/resource/DappConnection'

export class ListDappConnectionSchema extends DefaultSchema {
  readonly name = 'ListDappConnection'

  readonly fieldSet: FieldSet<DappConnection> = {
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
