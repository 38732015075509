import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import TokenCellRender from '@/components/render/TokenCellRender.vue'
import PercentageProgressRender from '@/components/render/PercentageProgressRender.vue'
import TruncateCellRender from '@/components/render/TruncateCellRender.vue'
import TokensPriceChart from '@/components/tokensprice/TokensPriceChart.vue'
import TokensSwapButton from '@/components/tokensprice/TokensSwapButton.vue'
import {TokensTableItem} from '@/model/resource/TokensTableItem'
import {DEFAULT_NETWORK} from '@/libs/blockchain-services/constants/BSNeo3Constants'
import {FlamingoSwapHelper} from '@/libs/blockchain-services/helpers/FlamingoSwapHelper'
import TokenImageRender from '@/components/render/TokenImageRender.vue'

export class SimpleListTokensTableItemSchema extends DefaultSchema {
  readonly name = 'ListTokensTableItem'

  isMobile = false

  orderByList = [
    'tokens',
    'price',
    'priceVariationLastDay',
    'priceVariationLastWeek',
    'marketCap',
    'totalSupply',
  ]

  swappableTokensSymbol = FlamingoSwapHelper.listSwappableTokensSymbol(
    DEFAULT_NETWORK
  )

  readonly fieldSet: FieldSet<TokensTableItem> = {
    image: (schema): FieldComponent => ({
      is: TokenImageRender,
      bind: {
        imageUrl: schema.model.imageUrl,
      },
    }),
    metadataInfo: (schema): FieldComponent => ({
      is: TokenCellRender,
      bind: {
        symbol: schema.model.symbol,
        slug: schema.model.marketInformation?.slugDapp,
        ownerAddress: schema.model.marketInformation?.creatorAddress,
        titleDapp: schema.model.marketInformation?.titleDapp,
        blockchainTagName: schema.model.blockchainVersion?.tagName,
      },
    }),
    price: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.shouldDisplayMarketInformation
          ? schema.model.priceFormatted
          : '',
      },
    }),
    priceVariationLastDay: (schema): FieldComponent => ({
      is: PercentageProgressRender,
      bind: {
        percentageValue: schema.model.shouldDisplayMarketInformation
          ? schema.model.priceVariationLastDayFormatted
          : undefined,
        showArrows: false,
        inputClass: 'text-lg md:text-lg',
        rounded: false,
      },
    }),
    priceVariationLastWeek: (schema): FieldComponent => ({
      is: PercentageProgressRender,
      bind: {
        percentageValue: schema.model.shouldDisplayMarketInformation
          ? schema.model.priceVariationLastWeekFormatted
          : undefined,
        showArrows: false,
        inputClass: 'text-lg md:text-lg',
        rounded: false,
      },
    }),
    marketCap: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.shouldDisplayMarketInformation
          ? schema.model.marketCapFormatted
          : '',
      },
    }),
    totalSupply: (schema): FieldComponent => ({
      is: TruncateCellRender,
      bind: {
        fullValue: schema.model.shouldDisplayMarketInformation
          ? schema.model.totalSupplyFormatted
          : '',
        symbol: schema.model.shouldDisplayMarketInformation
          ? schema.model.symbol
          : '',
      },
    }),
    priceVariationLastWeekGraphic: (schema): FieldComponent => ({
      is: TokensPriceChart,
      bind: {
        prices: schema.model.shouldDisplayMarketInformation
          ? schema.model.marketInformation?.variationGraphValues
          : [],
        priceVariationLastWeek: schema.model.marketInformation
          ? schema.model.priceVariationLastWeekFormatted
          : 0,
        class: 'py-2 text-base xl:text-lg',
      },
    }),
    buttonSwap: (schema): FieldComponent => ({
      is: TokensSwapButton,
      bind: {
        isSwappable: this.swappableTokensSymbol.includes(schema.model.symbol!),
        token: schema.model,
        class: schema.model.shouldDisplayMarketInformation
          ? 'py-2 text-base xl:text-lg'
          : 'hidden',
      },
    }),
  }
}
