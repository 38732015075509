

























































































































































import {Component, Mixins} from 'vue-property-decorator'
import TopDappsCard from '@/components/cards/TopDappsCard.vue'
import N3StatisticsCard from '@/components/cards/N3StatisticsCard.vue'
import TrendingTokensCard from '@/components/cards/TrendingTokensCard.vue'
import RecentlyAddedCard from '@/components/cards/RecentlyAddedCard.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import {DappCollection} from '@/model/collection/DappCollection'
import {BlockchainHourlyCacheCollection} from '@/model/collection/BlockchainHourlyCacheCollection'
import moment from 'moment'
import {BlockchainHourlyCache} from '@/model/resource/BlockchainHourlyCache'
import {NftCollection} from '@/model/resource/NftCollection'
import {NftCollectionCollection} from '@/model/collection/NftCollectionCollection'
import TopNftsCard from '@/components/cards/TopNftsCard.vue'
import GetGasOpenModalButton from '@/components/getGas/GetGasOpenModalButton.vue'
import {TokensTableItem} from '@/model/resource/TokensTableItem'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import NeoXStatisticsCard from '@/components/cards/NeoXStatisticsCard.vue'
import {ContractCounterCollection} from '@/model/collection/ContractCounterCollection'
import {ContractCollection} from '@/model/collection/ContractCollection'
import {Dapp} from '@/model/resource/Dapp'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import {Swiper as SwiperClass} from 'swiper'

@Component({
  components: {
    NeoXStatisticsCard,
    TopNftsCard,
    N3StatisticsCard,
    TopDappsCard,
    RecentlyAddedCard,
    TrendingTokensCard,
    GetGasOpenModalButton,
    Swiper,
    SwiperSlide,
  },
})
export default class NetworkStatsView extends Mixins(MixinScreenSize) {
  isBeginning = true
  isEnd = true

  swiper!: SwiperClass

  blockchainVersionCollection = new BlockchainVersionCollection()
  blockchainHourlyCacheCollection = new BlockchainHourlyCacheCollection()
  dappCollection = new DappCollection()
  contractCounterCollection = new ContractCounterCollection()
  tokensTableItemsCollection = new TokensTableItemsCollection()
  nftCollectionCollection = new NftCollectionCollection()
  contractCollection = new ContractCollection()

  neo3StatisticsCurrentDayItems: BlockchainHourlyCache[] = []
  neo3StatisticsLastWeekItems: BlockchainHourlyCache[] = []

  neoXStatisticsCurrentDayItems: BlockchainHourlyCache[] = []
  neoXStatisticsLastWeekItems: BlockchainHourlyCache[] = []

  topDappsItems: Dapp[] = []
  recentlyAddedItems: Dapp[] = []
  trendingTokensItems: TokensTableItem[] = []
  topNftsItems: NftCollection[] = []

  get swiperOption() {
    return {
      pagination: {
        el: '.stats-swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          slidesPerGroup: 1,
          spaceBetween: 24,
        },
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 24,
        },
        1280: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 24,
        },
      },
    }
  }

  async populateResources() {
    const promises: Array<Promise<any>> = [
      this.populateN3StatisticsCard(),
      this.populateNeoXStatisticsCard(),
      this.populateTrendingTokensCard(),
      this.populateTopNftsCard(),
      this.populateRecentlyAddedDappsCard(),
      this.populateTopDappsCard(),
    ]

    await this.$await.run('loadNetworkStats', () => Promise.all(promises))
  }

  async mounted() {
    await this.populateResources()
  }

  async populateN3StatisticsCard() {
    this.blockchainVersionCollection.networkIdentifier = 'neo3/mainnet'

    const {
      data: {items},
    } = await this.blockchainVersionCollection.listBlockchainVersion()

    const n3VersionId = items[0].idBlockchainVersionPk

    await this.populateN3LastWeekItems(n3VersionId)
    await this.populateN3CurrentDayItems(n3VersionId)
  }

  async populateNeoXStatisticsCard() {
    this.blockchainVersionCollection.networkIdentifier = 'neox/mainnet'

    const {
      data: {items},
    } = await this.blockchainVersionCollection.listBlockchainVersion()

    const neoXVersionId = items[0].idBlockchainVersionPk

    await this.populateNeoXLastWeekItems(neoXVersionId)
    await this.populateNeoXCurrentDayItems(neoXVersionId)
  }

  async populateTopDappsCard() {
    this.dappCollection.orderBy = 'recentTransactionCount'
    this.dappCollection.asc = false
    this.dappCollection.perPage = 3
    this.dappCollection.private = false
    this.dappCollection.isDapp = true

    const {
      data: {items},
    } = await this.dappCollection.queryAsPage()

    this.topDappsItems = items
  }

  async populateRecentlyAddedDappsCard() {
    this.dappCollection.orderBy = 'publishedDate'
    this.dappCollection.asc = false
    this.dappCollection.perPage = 3
    this.dappCollection.private = false
    this.dappCollection.isDapp = null

    const {
      data: {items},
    } = await this.dappCollection.queryAsPage()

    this.recentlyAddedItems = items
  }

  async populateTrendingTokensCard() {
    this.tokensTableItemsCollection.orderBy = 'priceVariationLastDay'
    this.tokensTableItemsCollection.withoutTotal = true
    this.tokensTableItemsCollection.asc = false
    this.tokensTableItemsCollection.perPage = 3

    const {
      data: {items},
    } = await this.tokensTableItemsCollection.listTokensTableItems()

    this.trendingTokensItems = items
  }

  async populateTopNftsCard() {
    this.nftCollectionCollection.orderBy = 'monthVolume'
    this.nftCollectionCollection.asc = false
    this.nftCollectionCollection.perPage = 3

    const {
      data: {items},
    } = await this.nftCollectionCollection.listNftCollection()

    this.topNftsItems = items
  }

  async initSwiper(swiper: SwiperClass) {
    this.swiper = swiper
    this.updateEvent()
    this.updateSwiper()
  }

  updateEvent() {
    this.isBeginning = this.swiper.isBeginning
    this.isEnd = this.swiper.isEnd
  }

  updateSwiper() {
    if (this.swiper) {
      this.swiper.update()
    }
  }

  prevSlide() {
    if (!this.swiper.isBeginning) {
      this.swiper.slidePrev()
    }
    this.updateEvent()
  }

  nextSlide() {
    if (!this.swiper.isEnd) {
      this.swiper.slideNext()
    }
    this.updateEvent()
  }

  private async populateN3LastWeekItems(idBlockchainVersion: number) {
    this.blockchainHourlyCacheCollection.startDate = moment()
      .subtract(1, 'week')
      .startOf('hour')
      .format()
    this.blockchainHourlyCacheCollection.idBlockchainVersionFk = idBlockchainVersion

    const {
      data: {items},
    } = await this.blockchainHourlyCacheCollection.listBlockchainHourlyCache()

    this.neo3StatisticsLastWeekItems = items
  }

  private async populateN3CurrentDayItems(idBlockchainVersion: number) {
    this.blockchainHourlyCacheCollection.orderBy = 'date'
    this.blockchainHourlyCacheCollection.startDate = moment()
      .startOf('day')
      .format()
    this.blockchainHourlyCacheCollection.asc = false
    this.blockchainHourlyCacheCollection.idBlockchainVersionFk = idBlockchainVersion

    const {
      data: {items},
    } = await this.blockchainHourlyCacheCollection.listBlockchainHourlyCache()

    this.neo3StatisticsCurrentDayItems = items
  }

  private async populateNeoXLastWeekItems(idBlockchainVersion: number) {
    this.blockchainHourlyCacheCollection.startDate = moment()
      .subtract(1, 'week')
      .startOf('hour')
      .format()
    this.blockchainHourlyCacheCollection.idBlockchainVersionFk = idBlockchainVersion

    const {
      data: {items},
    } = await this.blockchainHourlyCacheCollection.listBlockchainHourlyCache()

    this.neoXStatisticsLastWeekItems = items
  }

  private async populateNeoXCurrentDayItems(idBlockchainVersion: number) {
    this.blockchainHourlyCacheCollection.orderBy = 'date'
    this.blockchainHourlyCacheCollection.startDate = moment()
      .startOf('day')
      .format()
    this.blockchainHourlyCacheCollection.asc = false
    this.blockchainHourlyCacheCollection.idBlockchainVersionFk = idBlockchainVersion

    const {
      data: {items},
    } = await this.blockchainHourlyCacheCollection.listBlockchainHourlyCache()

    this.neoXStatisticsCurrentDayItems = items
  }
}
