import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet} from '@simpli/meta-schema'
import {Token} from '@/model/resource/Token'

export class ExportTokenSchema extends DefaultSchema {
  readonly name = 'ExportToken'

  readonly fieldSet: FieldSet<Token> = {
    idTokenPk: schema => schema.model.idTokenPk,
    title: schema => schema.model.title,
    active: schema => $.filter.bool(schema.model.active),
  }
}
