import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {IBoostedDappCollectionResourcesHolder} from '@/model/collection/BoostedDappCollection'
import {BoostedDapp} from '@/model/resource/BoostedDapp'
import {DappCollection} from '@/model/collection/DappCollection'

export class FilterBoostedDappSchema extends DefaultSchema
  implements IBoostedDappCollectionResourcesHolder {
  collectionDapp = new DappCollection().noPagination()

  readonly name = 'FilterBoostedDapp'

  readonly fieldSet: FieldSet<BoostedDapp> = {
    minOrder: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        label: this.translateFrom(schema.fieldName),
      },
    }),
    maxOrder: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        label: this.translateFrom(schema.fieldName),
      },
    }),
  }
}
