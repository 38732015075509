import {Request} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {PlatformTypeCollection} from '@/model/collection/PlatformTypeCollection'

export class PlatformType implements IResource {
  idPlatformTypePk: number = 0

  active: boolean | null = null

  title: string | null = null
  isMobile: boolean | null = false
  isDesktop: boolean | null = false
  isWeb: boolean | null = false
  isHardware: boolean | null = false

  get $id() {
    return this.idPlatformTypePk
  }

  set $id(val) {
    this.idPlatformTypePk = val
  }

  get $tag() {
    return String(this.title)
  }

  /**
   * Lists the instances of PlatformType
   */
  static async listPlatformType(params: any) {
    return await Request.get(`/client/platform-type`, {params})
      .name('listPlatformType')
      .as(PlatformTypeCollection)
      .getData()
  }

  /**
   * Gets a instance of a given ID of PlatformType
   */
  async getPlatformType(id: number) {
    return await Request.get(`/client/platform-type/${id}`)
      .name('getPlatformType')
      .as(this)
      .getData()
  }

  /**
   * Deletes a instance of a given ID of PlatformType
   */
  async removePlatformType() {
    return await Request.delete(`/client/platform-type/${this.$id}`)
      .name('removePlatformType')
      .asNumber()
      .getData()
  }

  /**
   * Persists a new instance of PlatformType. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistPlatformType() {
    return await Request.post(`/client/platform-type`, this)
      .name('persistPlatformType')
      .asNumber()
      .getData()
  }
}
