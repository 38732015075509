import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {Banner} from '@/model/resource/Banner'

@HttpExclude()
export class BannerCollection extends ExpansibleCollection<Banner> {
  resource?: IBannerCollectionResourcesHolder
  @RequestExpose() startVisibleStartDatetime: string | null = null
  @RequestExpose() endVisibleStartDatetime: string | null = null
  @RequestExpose() startVisibleEndDatetime: string | null = null
  @RequestExpose() endVisibleEndDatetime: string | null = null
  @RequestExpose() minOrder: number | null = null
  @RequestExpose() maxOrder: number | null = null
  @RequestExpose() websiteSection: string | null = null

  constructor() {
    super(Banner)
  }

  queryAsPage() {
    return this.listBanner()
  }

  async listBanner() {
    return await Request.get(`/client/banner`, {params: this.params})
      .name('listBanner')
      .as(this)
      .getResponse()
  }

  async listExportBanner() {
    return await Request.get(`/client/banner/export`, {params: this.params})
      .name('listExportBanner')
      .as(this)
      .getResponse()
  }
}

export interface IBannerCollectionResourcesHolder {}
