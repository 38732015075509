import {IResource} from '@simpli/resource-collection'
import {Request} from '@simpli/serialized-request'
import {SupportedTokenCollection} from '@/model/collection/SupportedTokenCollection'

export class SupportedToken implements IResource {
  idSupportedTokenPk: number = 0
  title: string | null = null
  order: number | null = null
  imageUrl: string | null = null

  get $id() {
    return this.idSupportedTokenPk
  }

  set $id(val) {
    this.idSupportedTokenPk = val
  }

  get $tag() {
    return String(this.title)
  }

  /**
   * Lists the instances of SupportedToken
   */
  static async listSupportedToken(params: any) {
    return await Request.get(`/client/supportedToken`, {params})
      .name('listSupportedToken')
      .as(SupportedTokenCollection)
      .getData()
  }

  /**
   * Gets an instance of a given ID of SupportedToken
   */
  async getSupportedToken(id: number) {
    return await Request.get(`/client/supportedToken/${id}`)
      .name('getSupportedToken')
      .as(this)
      .getData()
  }

  /**
   * Deletes an instance of a given ID of SupportedToken
   */
  async removeSupportedToken() {
    return await Request.delete(`/client/supportedToken/${this.$id}`)
      .name('removeSupportedToken')
      .asNumber()
      .getData()
  }

  /**
   * Persists a new instance of SupportedToken. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistSupportedToken() {
    return await Request.post(`/client/supportedToken`, this)
      .name('persistSupportedToken')
      .asNumber()
      .getData()
  }

  /**
   * Increments the order of SupportedToken
   */
  async changeOrder() {
    return await Request.post(`/client/supportedToken/${this.$id}/order`, this)
      .name('changeOrder')
      .asNumber()
      .getData()
  }
}
