

























































































































































































import {Component, Prop} from 'vue-property-decorator'
import PoweredBy from '@/components/PoweredBy.vue'
import TokensPriceTable from '@/components/tokensprice/TokensPriceTable.vue'
import LastPriceUpdated from '@/components/LastPriceUpdated.vue'
import {Dapp} from '@/model/resource/Dapp'
import {PoweredByItem} from '../homepage/explore-tokens-and-nfts/ExploreTokensPoweredBy.vue'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import {NftCollectionCollection} from '@/model/collection/NftCollectionCollection'
import NftCollectionTable from '@/components/nfts/NftCollectionTable.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  components: {
    NftCollectionTable,
    LastPriceUpdated,
    TokensPriceTable,
    PoweredBy,
  },
})
export default class DappDetailedTokensNftsSection extends MixinScreenSize {
  @Prop({type: Dapp, required: true}) dapp!: Dapp
  @Prop({type: TokensTableItemsCollection, required: true})
  tokensTableItemsCollection!: TokensTableItemsCollection
  @Prop({type: NftCollectionCollection, required: true})
  nftCollectionCollection!: NftCollectionCollection

  tokensTableLastTimeUpdated: string | null = null

  shouldDisplayTokensTable = true

  tokensTablePoweredByList = [
    {
      url: 'https://flamingo.finance/',
      alt: 'flamingo icon',
      filename: 'flamingo-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://www.forthewin.network/#/',
      alt: 'ftwicon',
      filename: 'for-the-win-logo.svg',
    } as PoweredByItem,
    {
      url: 'https://neofura-1.gitbook.io/api-docs/',
      alt: 'magnet icon',
      filename: 'magnet-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://carrot-fi.xyz/',
      alt: 'carrot swap icon',
      filename: 'carrot-swap.svg',
    } as PoweredByItem,
  ]

  nftsPoweredByList = [
    {
      url: 'https://ghostmarket.io/',
      alt: 'ghostmarket icon',
      filename: 'ghostmarket-icon.svg',
    } as PoweredByItem,
  ]

  get existsNfts() {
    return !this.nftCollectionCollection.isEmpty()
  }

  get existsTokens() {
    return !this.tokensTableItemsCollection.isEmpty()
  }

  mounted() {
    this.shouldDisplayTokensTable = this.existsTokens
  }

  handleChangeTokensTableLastTimeUpdated(lastTimeUpdated: string) {
    this.tokensTableLastTimeUpdated = lastTimeUpdated
  }
}
