import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {Category} from '@/model/resource/Category'

export class ListCategorySchema extends DefaultSchema {
  readonly name = 'ListCategory'

  readonly fieldSet: FieldSet<Category> = {
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
