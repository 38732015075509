import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {Dapp} from '@/model/resource/Dapp'
import {NntTagCollection} from '@/model/collection/NntTagCollection'
import {TokenCollection} from '@/model/collection/TokenCollection'
import {WalletCollection} from '@/model/collection/WalletCollection'
import FileManagerWithList from '@/components/FileManagerWithList.vue'
import {$} from '@/facade'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import {PrivateCategoryCollection} from '@/model/collection/PrivateCategoryCollection'

export class InputDappSchema extends DefaultSchema {
  collectionNntTag = new NntTagCollection().noPagination()
  collectionToken = new TokenCollection().noPagination()
  collectionWallet = new WalletCollection().noPagination()
  collectionBlockchain = new BlockchainVersionCollection().noPagination()
  collectionPrivateCategory = new PrivateCategoryCollection().noPagination()

  readonly name = 'InputDapp'

  readonly fieldSet: FieldSet<Dapp> = {
    title: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 70,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    slug: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 70,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    shortDescription: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 127,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    websiteUrl: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 255,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    companyName: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 70,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    description: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'textarea',
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
        class: 'col-span-2',
        rows: 5,
      },
    }),
    imageUrl: (schema): FieldComponent => ({
      is: FileManagerWithList,
      bind: {
        folder: 'dapp',
        compressDimension: $.auth.global.dappImageDimension,
        crop: true,
        class: 'scoped__image-url',
      },
    }),
    dappNntTag: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        label: this.translateFrom(schema.fieldName),
        items: this.collectionNntTag.items,
      },
    }),
    dappToken: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        label: this.translateFrom(schema.fieldName),
        items: this.collectionToken.items,
      },
    }),
    blockchainVersion: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        label: this.translateFrom(schema.fieldName),
        items: this.collectionBlockchain.items,
      },
    }),
    privateCategory: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        label: this.translateFrom(schema.fieldName),
        items: this.collectionPrivateCategory.items,
      },
    }),
    private: (schema): FieldComponent => ({
      is: Component.InputCheckbox,
      bind: {
        label: this.translateFrom(schema.fieldName),
      },
    }),
    inActiveDevelopment: (schema): FieldComponent => ({
      is: Component.InputCheckbox,
      bind: {
        label: this.translateFrom(schema.fieldName),
      },
    }),
    dappWallet: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        label: this.translateFrom(schema.fieldName),
        items: this.collectionWallet.items,
      },
    }),
  }
}
