import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {IWalletCollectionResourcesHolder} from '@/model/collection/WalletCollection'
import {Wallet} from '@/model/resource/Wallet'
import {DappCollection} from '@/model/collection/DappCollection'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import {PlatformTypeCollection} from '@/model/collection/PlatformTypeCollection'
import {DappConnectionCollection} from '@/model/collection/DappConnectionCollection'

export class FilterWalletSchema extends DefaultSchema
  implements IWalletCollectionResourcesHolder {
  collectionDapp = new DappCollection().noPagination()
  collectionBlockchainVersion = new BlockchainVersionCollection().noPagination()
  collectionPlatformType = new PlatformTypeCollection().noPagination()
  collectionDappConnection = new DappConnectionCollection().noPagination()

  readonly name = 'FilterWallet'

  readonly fieldSet: FieldSet<Wallet> = {
    walletBlockchainVersion: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        items: this.collectionBlockchainVersion.items,
        multiple: true,
        closeOnSelect: true,
        preserveSearch: true,
        placeholder: this.translateFrom(schema.fieldName),
        trackBy: '$id',
        preselectFirst: true,
      },
    }),
    walletDappConnection: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        items: this.collectionDappConnection.items,
        multiple: true,
        closeOnSelect: true,
        preserveSearch: true,
        placeholder: this.translateFrom(schema.fieldName),
        trackBy: '$id',
        preselectFirst: true,
      },
    }),
    walletPlatformType: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        items: this.collectionPlatformType.items,
        multiple: true,
        closeOnSelect: true,
        preserveSearch: true,
        placeholder: this.translateFrom(schema.fieldName),
        trackBy: '$id',
        preselectFirst: true,
      },
    }),
  }
}
