import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet} from '@simpli/meta-schema'
import {Category} from '@/model/resource/Category'

export class ExportCategorySchema extends DefaultSchema {
  readonly name = 'ExportCategory'

  readonly fieldSet: FieldSet<Category> = {
    idCategoryPk: schema => schema.model.idCategoryPk,
    title: schema => schema.model.title,
    active: schema => $.filter.bool(schema.model.active),
  }
}
