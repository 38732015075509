import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {SupportedToken} from '@/model/resource/SupportedToken'

@HttpExclude()
export class SupportedTokenCollection extends ExpansibleCollection<
  SupportedToken
> {
  resource?: ISupportedTokenCollectionResourcesHolder
  @RequestExpose() minOrder: number | null = null
  @RequestExpose() maxOrder: number | null = null

  constructor() {
    super(SupportedToken)
  }

  queryAsPage() {
    return this.listSupportedToken()
  }

  async listSupportedToken() {
    return await Request.get(`/client/supportedToken`, {params: this.params})
      .name('listSupportedToken')
      .as(this)
      .getResponse()
  }
}

export interface ISupportedTokenCollectionResourcesHolder {}
