import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {Banner} from '@/model/resource/Banner'
import FileManagerWithList from '@/components/FileManagerWithList.vue'
import {$} from '@/facade'
import {Global} from '@/model/resource/Global'
import {InputDateTime} from '@/components/inputs/InputDateTime'

export class InputBannerSchema extends DefaultSchema {
  readonly name = 'InputBanner'

  readonly fieldSet: FieldSet<Banner> = {
    order: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        maxlength: 255,
        step: 1,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    title: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 127,
        label: this.translateFrom(schema.fieldName),
      },
    }),
    type: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 127,
        label: this.translateFrom(schema.fieldName),
      },
    }),
    description: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 127,
        label: this.translateFrom(schema.fieldName),
      },
    }),
    tags: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 127,
        label: this.translateFrom(schema.fieldName),
      },
    }),
    visibleStartDatetime: (schema): FieldComponent => ({
      is: InputDateTime,
      bind: {
        type: 'mask',
        maskPreset: 'datetime',
        label: this.translateFrom(schema.fieldName),
      },
    }),
    visibleEndDatetime: (schema): FieldComponent => ({
      is: InputDateTime,
      bind: {
        type: 'mask',
        maskPreset: 'datetime',
        label: this.translateFrom(schema.fieldName),
      },
    }),
    websiteSectionAsIResource: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        items: Global.bannerWebsiteSections,
        allowEmpty: false,
        searchable: false,
        label: this.translateFrom(schema.fieldName),
        inputClass: 'multiselect--hide-checkbox',
      },
    }),
    imageUrl: (): FieldComponent => ({
      is: FileManagerWithList,
      bind: {
        folder: 'banner',
        compressDimension: $.auth.global.dappImageDimension,
        crop: false,
        class: 'scoped__image-url',
      },
    }),
  }
}
